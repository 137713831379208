/* eslint-disable react/prop-types */
import "./index.css";

import { FunctionComponent } from 'react'
import { useNavigate } from "react-router-dom";
import { openInNewTab } from "shared/utils/actions";

const HistoryAndLegacy: FunctionComponent = () => {
    const navigate = useNavigate();
    return (
        <div className='after-banner-section'>
            <div className="resource-links">
                <div
                    className='link'
                    onClick={() => openInNewTab('https://smartapstorageaccount.blob.core.windows.net/production/AGHS VOL. 1.pdf')}
                >
                    AGHS Newsletter
                </div>
                <div
                    className='link'
                    onClick={() => openInNewTab('https://smartapstorageaccount.blob.core.windows.net/production/Management Plan 2025.pdf')}
                >
                    Management Plan 2025
                </div>
                <div
                    className='link'
                    onClick={() => openInNewTab('https://smartapstorageaccount.blob.core.windows.net/production/INCWADI YOKUVALA ITHEMU YOKUQALA-2024.pdf')}
                >
                    INCWADI YOKUVALA ITHEMU YOKUQALA-2024
                </div>
            </div>
            <div className="top-options">
                <div className="option" onClick={() => document.getElementById("legacy")?.scrollIntoView({ block: 'center' })}>
                    <img className="image" src={require("../../../assets/images/home-page/icons/College-life.png")} alt="" title="School-Fees" />
                    <div className="side-text">
                        <div className="title">School History</div>
                        <div className="sub-title">The Legacy</div>
                    </div>
                </div>
                <div className="option" onClick={() => {
                    document.getElementById('about')?.scrollIntoView({ block: 'center' })
                }}>
                    <img className="image" src={require("../../../assets/images/home-page/icons/LAtest-News.png")} alt="" title="School-Fees" />
                    <div className="side-text">
                        <div className="title">About</div>
                        <div className="sub-title">School Info</div>
                    </div>
                </div>
                <div className="option" onClick={() => document.getElementById('statistics')?.scrollIntoView({ block: 'center' })}>
                    <img className="image" src={require("../../../assets/images/home-page/icons/Boarding.png")} alt="" title="School-Fees" />
                    <div className="side-text">
                        <div className="title">Statistics</div>
                        <div className="sub-title">School Stats</div>
                    </div>
                </div>
                <div className="option" onClick={() => document.getElementById('testimonials')?.scrollIntoView({ block: 'center' })}>
                    <img className="image" src={require("../../../assets/images/home-page/icons/LAtest-News.png")} alt="" title="School-Fees" />
                    <div className="side-text">
                        <div className="title">Testimonials</div>
                        <div className="sub-title">Find Out More</div>
                    </div>
                </div>
            </div>
            <div id="history-and-legacy" className="bottom-section">
                <div className="left-section" />
                <div id="legacy" className="right-section">
                    <div className="right-section-box">
                        <div className="title">School History & Legacy</div>
                        <div className="sub-title">A High School Where History Guides Great Futures</div>
                        <div className="paragraph">The legacy of your school years will last a lifetime. At Albini High School, there is a place for everyone to develop their strengths and build upon their talents.</div>
                        <div className="paragraph">Apply today and begin building your future.</div>
                        <div
                            onClick={() => navigate('/applications')}
                            className="button"
                        >APPLICATIONS</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HistoryAndLegacy;
