import { FileUpload } from '@patternfly/react-core';
import FileUploadIcon from '@patternfly/react-icons/dist/esm/icons/file-upload-icon';

const DocumentUpload = ({
  filenamePlaceholder,
  file,
  setFile
}) => (
      <FileUpload
        id="customized-preview-file"
        value={file.value}
        filename={file.filename}
        filenamePlaceholder={filenamePlaceholder}
        onFileInputChange={(event, file) => setFile({ 
          ...file,
          value: file, 
          filename: file.name 
        })}
        onClearClick={() => setFile({
          value: null,
          filename: ''
        })}
        hideDefaultPreview
        browseButtonText="Upload"
      >
        {file.value && (
          <div className="pf-u-m-md">
            <FileUploadIcon size="lg" /> {file.value.name}
          </div>
        )}
      </FileUpload>
);

export default DocumentUpload;