/* eslint-disable react/prop-types */
import { FunctionComponent, useContext, useEffect } from "react";
import { Grid } from "@material-ui/core";

import useStyles from "registration/shared/utils/use-styles";
import CustomTextField from "registration/shared/components/dimensional-text-fields/2D-text-field";
import { ApplicationDetailsContext } from "registration/store";
import { CurrentUserContext } from "store";
import { isEmpty } from "shared/utils/string";

const Declaration: FunctionComponent = () => {
  const classes = useStyles();
  const [currentUser] = useContext(CurrentUserContext);
  const [applicationDetails, setApplicationDetails] = useContext(ApplicationDetailsContext)  
 
  useEffect(() => document.getElementById('declaration-details')!.scrollIntoView(), [])
  useEffect(() => {
    if(!isEmpty(currentUser)) setApplicationDetails({ ...applicationDetails, UserId: currentUser.id })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  return (
    <div className={classes.root} id="declaration-details">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <label style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
            SECTION C
          </label>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <label style={{ fontWeight: 'bold' }}>
            DECLARATION /UNDERTAKINGS BY APPLICANTS AND PARENTS/GUARDIANS
          </label>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <label>
            1. We have read and understod the statement and questions on this form. The information supplied by us, 
            individually or together, is complete and true in every respect. If any of the supplied information is found to be
            incomplete, incorrect, untrue, misleading, the school may cancel offer of space and refuse to accept any future
            application in respect of the same applicant.
          </label>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <label>
            2. We undertake to accept and abide by the code of conduct of the school ,and such rules regulations are put in
            place by the School Goverment Body from time to time. We accept further that the applicant will be under the
            disciplinary control of the school from the date on which the commences her studied at the school ,to the date on
            which she is withdrawn from or leaves the school.
          </label>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <label>
            3. We accept that the school may
          </label>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <label>
            3.1. report to the same people on any matter concerning the progress, conduct, well-being or health of the
            applicant.
          </label>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <label>
            3.2. phone the same people if the applicant become ill or injured or for any reason requiring medical attention.
          </label>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <label>
            3.3. We accept liability for any damage to the school property caused by the applicant how so ever it may
            occur.
          </label>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <label>
            3.4. The address for the service of any formal notice in relation to this application may be served at the physocal
            address listed in section B 
          </label>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomTextField
            type="text"
            field1='declaration'
            field2='thisFormSignedAt'
            placeholder='THIS FORM IS SIGNED AT'
            helperText='THIS FORM IS SIGNED AT'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='declaration'
            field2='thisFormSignedOnDay'
            placeholder='ON THIS'
            helperText='ON THIS'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='declaration'
            field2='thisFormSignedOnMonth'
            placeholder='DAY OF                                2024'
            helperText='DAY OF ________________________ 2024'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <label style={{ fontWeight: 'bold' }}>
            SIGNATURES
          </label>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomTextField
            type="text"
            field1='declaration'
            field2='signatureOfLearner'
            placeholder='SIGNATURE OF LEARNER (INITIALS)'
            helperText='SIGNATURE OF LEARNER (INITIALS)'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <label style={{ fontWeight: 'bold' }}>
            1. PARENT/GUARDIAN
          </label>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomTextField
            type="text"
            field1='declaration'
            field2='nameAndSurnameOfGuardian1'
            placeholder='NAME AND SURNAME OF GUARDIAN'
            helperText='NAME AND SURNAME OF GUARDIAN'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='declaration'
            field2='signatureOfParentOrGuardian1'
            placeholder='SIGNATURE OF PARENT/GUARDIAN (INITIALS)'
            helperText='SIGNATURE OF PARENT/GUARDIAN (INITIALS)'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='declaration'
            field2='nameOfWitness1'
            placeholder='NAME OF WITNESS'
            helperText='NAME OF WITNESS'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='declaration'
            field2='signatureOfWitness1'
            placeholder='SIGNATURE OF WITNESS (INITIALS)'
            helperText='SIGNATURE OF WITNESS (INITIALS)'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <label style={{ fontWeight: 'bold' }}>
            2. PARENT/GUARDIAN
          </label>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomTextField
            type="text"
            field1='declaration'
            field2='nameAndSurnameOfGuardian2'
            placeholder='NAME AND SURNAME OF GUARDIAN'
            helperText='NAME AND SURNAME OF GUARDIAN'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='declaration'
            field2='signatureOfParentOrGuardian2'
            placeholder='SIGNATURE OF PARENT/GUARDIAN (INITIALS)'
            helperText='SIGNATURE OF PARENT/GUARDIAN (INITIALS)'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='declaration'
            field2='nameOfWitness2'
            placeholder='NAME OF WITNESS 2'
            helperText='NAME OF WITNESS 2'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='declaration'
            field2='signatureOfWitness2'
            placeholder='SIGNATURE OF WITNESS (INITIALS) 2'
            helperText='SIGNATURE OF WITNESS (INITIALS) 2'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <label>
            A FALSE DECLARATION OF ANY KIND WILL ENTITLE THE SCHOOL TO TERMINATE AN APPLICATION
          </label>
        </Grid>
      </Grid>
    </div>
  );
};

export default Declaration;
