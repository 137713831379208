/* eslint-disable react/prop-types */
import React from "react";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const FormControlSelect = ({
  label,
  options,
  classes,
  index,
  field,
  fieldId,
  fieldName,
  applicationDetails,
  setApplicationDetails
}) => (
  <FormControl className={classes.formControl} style={{ minWidth: '200px' }}>
    <InputLabel htmlFor={1}>{label}</InputLabel>
    <Select
      native
      fullWidth
      labelWidth={5}
      value={applicationDetails[field][fieldName]}
      onChange={event => {
        setApplicationDetails({
          ...applicationDetails,
          [field]: {
            ...applicationDetails[field],
            [fieldId]: options.find(o => o.name === event.target.value).id,
            [fieldName]: options.find(o => o.name === event.target.value).name
          }
        });
      }}
    >
      {options.map((prop, key) => (
        <option value={index ? key : prop.name} prop={prop} key={key}>
          {prop.name}
        </option>
      ))}
    </Select>
  </FormControl>
);

export default FormControlSelect;
