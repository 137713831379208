import './index.css';

import { useState } from 'react';

import TopNavbar from '../../sections/top-navbar'
import SecondNavbar from '../../sections/second-navbar'
import SideNavBar from '../../sections/side-navbar'
import Footer from '../../sections/footer';
import React from 'react';

type Props = {
    isHomePage: boolean;
    children: JSX.Element
};

const HeaderFooter: React.FC<Props> = ({
  isHomePage,
  children
}) => {
  const [isRightNavBarVisible, setRightNavBarVisible] = useState(false);
  React.useEffect(() => document.getElementById('home')!.scrollIntoView(), [])

  return (
    <div id='home' className="home">
      <SideNavBar 
        isVisible={isRightNavBarVisible} 
        setVisible={setRightNavBarVisible}
      />
      <div
        className={`
        ${
          isRightNavBarVisible
            ? "move-navbar-left"
            : isRightNavBarVisible
            ? "move-navbar-back"
            : ""
        }`}
      >
      <TopNavbar />
      <SecondNavbar
        isHomePage={isHomePage}
        isRightNavBarVisible={isRightNavBarVisible}
        setRightNavBarVisible={setRightNavBarVisible}
      />   
        {children}
      <Footer />
      </div>
    </div>
  );
}

export default HeaderFooter;