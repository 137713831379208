/* eslint-disable react/prop-types */
import "./index.css";

import { FunctionComponent } from 'react';
import { useNavigate } from "react-router-dom";

const Footer: FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <div id='footer' className='footer'>
        <div className="top">
          <div className="left">
            <div className="logo" />
            <div className="contact-details">
              <div className="text">210 D Section, Ntshongweni, Hammarsdale, 3700</div>
              <div className="text">P.O.Box 84, Hammarsdale, 3700</div>
              <div className="text">TEL: +27 72 415 0777</div>
              <div className="text">FAX: 033 394 6059</div>
            </div>
          </div>
          <div className="right">
            <div className="nav-section">
                <div className="heading">OUR SCHOOL</div>
                <div 
                  className="nav-item" 
                  onClick={() => document.getElementById("about")?.scrollIntoView({ block: 'center' })}
                >About</div>
                <div 
                  className="nav-item" 
                  onClick={() => document.getElementById("legacy")?.scrollIntoView({ block: 'center' })}
                >School History</div>
            </div>
            <div className="nav-section">
                <div className="heading">SERVICE</div>
                <div 
                  className="nav-item" 
                  onClick={() => document.getElementById("testimonials")?.scrollIntoView({ block: 'center' })}
                >Testimonials</div>
                <div 
                  className="nav-item" 
                  onClick={() => document.getElementById("statistics")?.scrollIntoView({ block: 'center' })}
                >Statistics</div>
            </div>
            <div className="nav-section">
                <div className="heading">ADMISSIONS</div>
                <div 
                  className="nav-item" 
                  onClick={() => document.getElementById("apply-now")?.scrollIntoView({ block: 'center' })}
                >Apply Now</div>
                <div 
                  className="nav-item" 
                  onClick={() => navigate("/applications")}
                >Applications</div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Footer;
