import './index.css'

import { FunctionComponent} from "react";
import HeaderFooter from 'home/sections/header-footer';
import { ApplicationsStats } from 'api/applications/types';
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Chart,
  BarSeries,
  ArgumentAxis,
  ValueAxis,
} from '@devexpress/dx-react-chart-bootstrap4';
import { Animation } from '@devexpress/dx-react-chart';

import * as applicationsApi from "api/applications";
import NotifyFailure from 'shared/utils/notify-failure';
import useApi from 'shared/utils/react_use_api';

const Stats: FunctionComponent = () => {

  const getApplicationsStats = useApi({
    action: () => applicationsApi.getApplicationsStats(),
    initialValue: {
      grade8Count: 0,
      grade9Count: 0,
      grade10Count: 0
    },
    onError: (error: any) => NotifyFailure(error.response, error.message)
  }, [])

  const data = [
    { grade: `Grade 8 (${getApplicationsStats.data.grade8Count})`, count: getApplicationsStats.data.grade8Count },
    { grade: `Grade 9 (${getApplicationsStats.data.grade9Count})`, count: getApplicationsStats.data.grade9Count },
    { grade: `Grade 10 (${getApplicationsStats.data.grade10Count})`, count: getApplicationsStats.data.grade10Count }
  ]
  return (
    <HeaderFooter isHomePage={false}>
      <div className='applications-stats'>
        <div className='background-banner' />
        <div className='main-section' >
          {getApplicationsStats.inProgress ? <div className='spinner'> <CircularProgress color="inherit" size={50} /> </div> : (
            <div className="card">
              <Chart
                data={data}
              >
                <ArgumentAxis />
                <ValueAxis />

                {data.map(({ grade }) => (
                  <BarSeries
                    key={grade}
                    valueField="count"
                    argumentField="grade"
                  />
                ))}
                <Animation />
              </Chart>
            </div>
          )}
        </div>
      </div>
    </HeaderFooter>
  );
}

export default Stats;