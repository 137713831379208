/* eslint-disable react/prop-types */
import "./index.css";

import { FunctionComponent, useContext, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faBank } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";
import { openInNewTab } from "shared/utils/actions";
import { CurrentUserContext, MyApplicationContext } from "store";
import { UseApi } from "api/applications/types";

type NavBarProps = {
  isVisible: boolean;
  setVisible: (value: boolean) => void;
}

const SideNavBar: FunctionComponent<NavBarProps> = ({
  isVisible,
  setVisible
}) => {
  const navigate = useNavigate();
  const [currentUser] = useContext(CurrentUserContext);
  const getMyApplication = useContext<UseApi>(MyApplicationContext);
  const [closeNavBarClicked, setcloseNavBarClicked] = useState(false)
  return (
    <div
      className={`side-nav-bar  ${isVisible ? "side-nav-bar-open" : closeNavBarClicked ? "side-nav-bar-close animation-slide-out-right" : "side-nav-bar-close"
        }`}
    >
      <div className="close-bar" onClick={() => {
        setVisible(false);
        setcloseNavBarClicked(true);
      }}>
        <FontAwesomeIcon className='animate__animated animate__zoomIn' color='#979797' icon={faClose} size='2x' />
      </div>
      <div className="side-nav-bar-items">
        <div
          className="side-nav-bar-item"
          onClick={() => navigate('/')}
        >
          HOME
        </div>
        <div
          className="side-nav-bar-item"
          onClick={() => navigate('/applications')}
        >
          APPLICATIONS
        </div>
        <div
          className="side-nav-bar-item"
          onClick={() => {
            getMyApplication.setData({})
            navigate(`/application/${currentUser.id}`)
          }}
          style={{ display: localStorage.getItem('albini-high-school-user') != null  ? 'flex': 'none'}}
        >
          MY APPLICATION
        </div>
        <div
          className="side-nav-bar-item"
          onClick={() => navigate('/applications-stats')}
          style={{ display: currentUser && currentUser.isAHSAdmin ? 'flex': 'none'}}
        >
          APPLICATIONS STATS
        </div>
        <div
          className='side-nav-bar-item'
          onClick={() => openInNewTab('https://focusmentorship.com')}
        >
          FOCUS MENTORSHIP
        </div>
        <div
          className='side-nav-bar-item'
          onClick={() => openInNewTab('https://smartapstorageaccount.blob.core.windows.net/production/AGHS VOL. 1.pdf')}
        >
          AGHS NEWSLETTER
        </div>
        <div
          className='side-nav-bar-item'
          onClick={() => openInNewTab('https://smartapstorageaccount.blob.core.windows.net/production/Management%20Plan%202025.pdf.pdf')}
        >
          MANAGEMENTG PLAN 2024
        </div>
        <div
          className='side-nav-bar-item'
          onClick={() => openInNewTab('https://smartapstorageaccount.blob.core.windows.net/production/INCWADI YOKUVALA ITHEMU YOKUQALA-2024.pdf')}
        >
          INCWADI YOKUVALA ITHEMU YOKUQALA-2024
        </div>
        <div className='side-nav-bar-item-info'>
          <FontAwesomeIcon color='white' icon={faBank} size='sm' /> FNB Account: 5940 1149 313
        </div>
        <div className='side-nav-bar-item-info'>
          <FontAwesomeIcon color='white' icon={faBank} size='sm' /> Branch Name: Hillcrest
        </div>
        <div className='side-nav-bar-item-info'>
          <FontAwesomeIcon color='white' icon={faBank} size='sm' /> Branch Code: 223 726
        </div>
        <div className='side-nav-bar-item-info'>
          Applications Open: 15 April 2024
        </div>
        <div className='side-nav-bar-item-info'>
          Applications Close: 31 May 2024
        </div>
      </div>
    </div>
  );
};

export default SideNavBar;
