import CustomeTextField from "../dimensional-text-fields/3D-text-field";

// eslint-disable-next-line react/prop-types
const Address = ({ field1, field2, helperText, objectDetails, setObjectDetails }) => {
  return (
    <>
      <CustomeTextField
        field1={field1}
        field2={field2}
        field3={"line1"}
        type="text"
        placeholder={"STREET"}
        helperText={helperText + ' STREET'}
        objectDetails={objectDetails}
        setObjectDetails={setObjectDetails}
      />
      <CustomeTextField
        field1={field1}
        field2={field2}
        field3={"line2"}
        type="text"
        placeholder={"AREA"}
        helperText={helperText + ' AREA'}
        objectDetails={objectDetails}
        setObjectDetails={setObjectDetails}
      />
      <CustomeTextField
        field1={field1}
        field2={field2}
        field3={"line3"}
        type="text"
        placeholder={"CITY"}
        helperText={helperText + ' CITY'}
        objectDetails={objectDetails}
        setObjectDetails={setObjectDetails}
      />
      <CustomeTextField
        field1={field1}
        field2={field2}
        field3={"postalCode"}
        type="number"
        placeholder={"POSTAL CODE"}
        helperText={helperText + ' POSTAL CODE'}
        objectDetails={objectDetails}
        setObjectDetails={setObjectDetails}
      />
    </>
  );
};

export default Address;
