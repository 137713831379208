/* eslint-disable react/prop-types */
import "./index.css";

import { FunctionComponent, useEffect, useRef } from 'react'
import { useCountUp } from 'react-countup';
import useOnScreen from "shared/utils/useOnScreen";


const StatsSection: FunctionComponent = () => {
  const ref: any = useRef();
  const isVisible = useOnScreen(ref);
  const sportCountUpRef = useRef(null);
  const staffCountUpRef = useRef(null);
  const historyCountUpRef = useRef(null);
  const academicsCountUpRef = useRef(null);

  const { start: sportStart } = useCountUp({ ref: sportCountUpRef, end: 316 });
  const { start: staffStart } = useCountUp({ ref: staffCountUpRef, end: 25 });
  const { start: historyStart } = useCountUp({ ref: historyCountUpRef, end: 73 });
  const { start: academicsStart } = useCountUp({ ref: academicsCountUpRef, end: 796 });

  useEffect(() => {
    if(isVisible) {
      sportStart()
      staffStart()
      historyStart()
      academicsStart()
  // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  return (
    <div ref={ref} id='statistics' className='stats-section'>
        <div className="record">
            <div className="value" ref={historyCountUpRef} />
            <div className="description">SCHOOL OPERATIONAL YEARS TO DATE</div>
            <div className="title">SCHOOL HISTORY</div>
        </div>
        <div className="record">
            <div className="value" ref={academicsCountUpRef} />
            <div className="description">STUDENTS WHO OBTAINED DISTINCTIONS TO DATE</div>
            <div className="title">ACADEMICS AT SCHOOL</div>
        </div>
        <div className="record">
            <div className="value" ref={sportCountUpRef} />
            <div className="description">ON AVERAGE STUDENT SPORT PARTICIPATION PER YEAR</div>
            <div className="title">SPORT AT SCHOOL</div>
        </div>
        <div className="record">
            <div className="value" ref={staffCountUpRef} />
            <div className="description">TOTAL SCHOOL STAFF MEMBERS TO DATE</div>
            <div className="title">OUR STAFF MEMBERS</div>
        </div>
    </div>
  );
};

export default StatsSection;
