import './navbar.css'

import { useNavigate } from "react-router-dom";
import { FunctionComponent, useState, useEffect, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faBars, faCircleUser } from '@fortawesome/free-solid-svg-icons'

import DropDown from './drop-down';

import { Option } from './types';
import { options } from './data';
import { CurrentUserContext } from 'store';

type NavBarProps = {
  isHomePage: boolean;
  isRightNavBarVisible: boolean;
  setRightNavBarVisible: (value: boolean) => void;
}

const NavBar: FunctionComponent<NavBarProps> = ({
  isHomePage,
  isRightNavBarVisible,
  setRightNavBarVisible
}) => {
  const navigate = useNavigate();
  const [currentUser] = useContext(CurrentUserContext);
  const [scrollTop, setScrollTop] = useState(0);
  const [, setScrolling] = useState(false);
  const [activeTab, setActiveTab] = useState('Home')
  const [activeOption, setActiveOption] = useState<Option>();

  useEffect(() => {
    const onScroll = (e: any) => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <nav className={scrollTop >= 50 ? 'second-navbar fixed-top' : 'second-navbar'}>
      <div className='left-section' onClick={() => navigate('/')}/>
      <div className='right-section' >
          <div 
            onClick={() => navigate('/')}
            className={activeTab === 'Home' ? 'item active' : 'item'}
            onMouseOver={() => {
              navigate('/')
              setActiveTab('Home')
              setActiveOption(undefined)
            }}
          >Home</div>
          {isHomePage && (
            <>
              <div 
                className={activeTab === 'About' ? 'item active' : 'item'}
                onMouseOver={() => {
                  setActiveTab('About')
                  setActiveOption(options[0])
                }}
              >About</div>
              <div
                className={activeTab === 'Admissions' ? 'item active' : 'item'}
                onMouseOver={() => {
                  setActiveTab('Admissions')
                  setActiveOption(options[1])
                }}
              >Admissions</div>
            </>
          )}
          <div 
            className={activeTab === 'Login' ? 'item active' : 'item'}
            onClick={() => navigate('/login') }
            onMouseOver={() => {
              setActiveTab('Login')
              setActiveOption(undefined)
            }}
          >
            <FontAwesomeIcon 
              style={{ padding: '5px' }} 
              color={activeTab === 'Login' ? 'item active' : 'gray'} 
              icon={faCircleUser} size='1x' 
            />
            {!currentUser.firstName ? 'Login' : currentUser.firstName}
          </div>
      </div>
      <div className='bars-close-icons' onClick={() => setRightNavBarVisible(!isRightNavBarVisible)}>
          <FontAwesomeIcon className={!isRightNavBarVisible ? 'hide' : 'animate__animated animate__zoomIn'} color='black' icon={faClose} size='2x' />
          <FontAwesomeIcon className={isRightNavBarVisible ? 'hide' : 'animate__animated animate__zoomIn'} color='black' icon={faBars} size='2x' />
      </div>
      {activeOption && (
        <DropDown 
          option={activeOption} 
          setActiveOption={setActiveOption}
        />
      )}
    </nav>
  )
}

export default NavBar;