import moment from "moment";
import { ApplicationAttachment, ApplicationForm } from "api/applications/types";
import { USER_FRIENDLY_FORMAT } from "shared/constants/strings";

export const selectApplications = (applications: ApplicationForm[]) : any => applications.map((ap: ApplicationForm) => ({
        ...ap,
        searchKey: ap.applicationStatus.name
            .concat(moment(ap.createdAt).format(USER_FRIENDLY_FORMAT))
            .concat(ap.user.firstName)
            .concat(ap.user.lastName)
            .concat(ap.learnerDetails.firstNamesFull)
            .concat(ap.learnerDetails.surname)
            .concat(ap.grade.name)
            .toLowerCase()
    })
)

export const selectRows = (applications: ApplicationForm[]) : any => applications.map((app: ApplicationForm) => ({
        id: app.id,
        date: new Date(app.createdAt),
        firstName: app.learnerDetails.firstNamesFull,
        lastName: app.learnerDetails.surname,
        gradeName: app.grade.name,
        reportsAverage: app.reportsAverage?.toFixed(2),
        applicationStatus: app.applicationStatus.name,
        applicationFeePaid: app.applicationAttachments.some((at: ApplicationAttachment) => at.attachmentDescription === 'ProofOfPaymentFileURL')
    })
)