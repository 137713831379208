import './drop-down.css'

import React, { FunctionComponent, useContext } from "react";
import { Option, Section, subSection } from '../types';
import { useNavigate } from 'react-router-dom';
import { CurrentUserContext } from 'store';


type DropDownProps = {
    option: Option;
    setActiveOption: (option: Option | undefined) => void;
}   

const DropDown: FunctionComponent<DropDownProps> = ({
    option,
    setActiveOption
}) => {
    const navigate = useNavigate();
    const [currentUser] = useContext(CurrentUserContext);

    return (
      <div 
        className='drop-down'
        onMouseLeave={() => setActiveOption(undefined)}
      >
          {option.sections.map(((section: Section, i) => 
            <>
                {section.type === 'header-sections' && (
                    <div 
                        key={`1${i}`} 
                        className="option-section">
                        <span key={`2${i}`} className='heading'>{section.heading}</span>
                        {section.subSections.map(((subSection: subSection, i) => 
                            <div 
                                key={`3${i}`} 
                                className='sub-section'
                                onClick={() => {
                                    if(subSection.isLink) navigate(`/${subSection.id}`)
                                    document.getElementById(subSection.id)?.scrollIntoView({ block: 'center' });
                                    setActiveOption(undefined);
                                }}
                                style={{ display: subSection.id === 'applications-stats' && (!currentUser || !currentUser.isAHSAdmin) ? 'none': 'flex'}}
                            >{subSection.description}</div>
                        ))}
                    </div>
                )}
                {section.type === 'logo-slogan' && (
                    <div 
                        key={`4${i}`}  
                        className="logo-slogan-section">
                        <img
                            key={`5${i}`}  
                            className='logo' 
                            src={require('../../../../assets/images/albini-high-school.png')} 
                            alt="Albini High School Logo" 
                            title="Long-Logo—Black"
                        />
                        <p 
                            key={`6${i}`}  
                            className='paragraph'>
                            Providing top-quality education to learners, we draw on our long and proud tradition, as well as our dynamic and forward-thinking ethos.
                        </p>
                    </div>
                )}
            </>
          ))}
      </div>
    )
}

export default DropDown;