import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";

import useStyles from "registration/shared/utils/use-styles";
import { ApplicationDetailsContext, RegistrationComponentsContext } from "registration/store";
import FormControlSelect from "registration/shared/components/dimensional-form-control-select/1D-form-control-select-pair";

// eslint-disable-next-line react/prop-types
const Grade = () => {
  const [registrationComponents] = useContext(RegistrationComponentsContext)
  const [applicationDetails, setApplicationDetails] = useContext(ApplicationDetailsContext)
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <FormControlSelect
          field='grade'
          fieldId='id'
          fieldName='name'
          label='Select grade'
          fullWidth={true}
          index={false}
          options={[{ id: 0, name: "" }].concat(registrationComponents.grades)}
          classes={classes}
          applicationDetails={applicationDetails}
          setApplicationDetails={setApplicationDetails}
        />
      </Grid>
    </div>
  );
};

export default Grade;
