import './application-tabs.css'

import { FunctionComponent } from "react";
import { useNavigate, useParams, useLocation } from 'react-router-dom';

type ApplicationTabsProps = {
    grade: string
};

const ApplicationTabs: FunctionComponent<ApplicationTabsProps> = ({
    grade
}) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const GradeSection = (firstReport: string, secondReport: string, forGrade: string) => (
        <span style={{ display: grade === forGrade ? 'flex' : 'none' }} className='grade-section'>
            <span className='application-tab-divider'>|</span>
            <div
                className={pathname.includes('first-report-grades') ? 'application-tab-active' : 'application-tab'}
                onClick={() => navigate(`/first-report-grades/${id}`)}
            >{firstReport}</div>
            <span className='application-tab-divider'>|</span>
            <div
                className={pathname.includes('second-report-grades') ? 'application-tab-active' : 'application-tab'}
                onClick={() => navigate(`/second-report-grades/${id}`)}
            >{secondReport}</div>
        </span>
    )

    return (
        <div className='application-tabs-container'>
            <div className='application-tabs'>
                <div
                    className={pathname.includes('application') ? 'application-tab-active' : 'application-tab'}
                    onClick={() => navigate(`/application/${id}`)}
                >APPLICATION</div>
                <span className='application-tab-divider'>|</span>
                <div
                    className={pathname.includes('attachments') ? 'application-tab-active' : 'application-tab'}
                    onClick={() => navigate(`/attachments/${id}`)}
                >ATTACHMENTS</div>


                {GradeSection('GRADE 6 MARKS', 'GRADE 7 MARKS', 'Grade 8')}

                {GradeSection('GRADE 7 MARKS', 'GRADE 8 MARKS', 'Grade 9')}

                {GradeSection('GRADE 8 MARKS', 'GRADE 9 MARKS', 'Grade 10')}

                {GradeSection('GRADE 9 MARKS', 'GRADE 10 MARKS', 'Grade 11')}

                {GradeSection('GRADE 10 MARKS', 'GRADE 11 MARKS', 'Grade 12')}
            </div>
        </div>
    );
}

export default ApplicationTabs;