/* eslint-disable react/prop-types */
import "./index.css";

import { FunctionComponent } from 'react';
import { openInNewTab } from "shared/utils/actions";

const SocialsSection: FunctionComponent = () => {
  return (
    <div className='socials-section'>
      <img  
        alt="logo"
        className="facebook-logo" 
        src={require('../../../assets/images/home-page/icons/facebook-logo-white.png')}
        onClick={() => openInNewTab("https://www.facebook.com/CatholicAGH")}
      />
      <img 
        className="instagram-logo" 
        src={require('../../../assets/images/home-page/icons/instagram-logo-white.png')}
        onClick={() => openInNewTab("https://www.instagram.com/albinigirlshighschool/")}
        alt="logo" 
      />
    </div>
  );
};

export default SocialsSection;
