/* eslint-disable react/prop-types */
import { FunctionComponent, useContext, useEffect, useState } from "react";
import {
  Grid,
  Select,
  InputLabel,
  FormControl 
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import Address from "registration/shared/components/address";
import useStyles from "registration/shared/utils/use-styles";
import CustomTextField from "registration/shared/components/dimensional-text-fields/2D-text-field";
import { ApplicationDetailsContext } from "registration/store";
import DocumentUpload from "registration/shared/components/file-upload";
import { CurrentUserContext } from "store";
import { isEmpty, isNullOrEmpty } from "shared/utils/string";

const LeanerDetails: FunctionComponent = () => {
  const classes = useStyles();
  const [currentUser] = useContext(CurrentUserContext);
  const [applicationDetails, setApplicationDetails] = useContext(ApplicationDetailsContext)  
  const [residencyCopy, setResidencyCopy] = useState({
    value: applicationDetails.learnerDetails.residencyCopyFile,
    isLoading: false
  })
  const [permitCopy, setPermitCopy] = useState({
    value: applicationDetails.learnerDetails.permitCopyFile,
    isLoading: false
  })
  

  useEffect(() => {
    if(permitCopy.value != null){      
      setApplicationDetails({
        ...applicationDetails,
        learnerDetails: {
          ...applicationDetails.learnerDetails,
          permitCopyFile: permitCopy.value
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permitCopy])

  useEffect(() => {
    if(residencyCopy.value != null){      
      setApplicationDetails({
        ...applicationDetails,
        learnerDetails: {
          ...applicationDetails.learnerDetails,
          residencyCopyFile: residencyCopy.value
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residencyCopy])

  useEffect(() => document.getElementById('learner-details')!.scrollIntoView(), [])
  useEffect(() => {
    if(!isEmpty(currentUser)) setApplicationDetails({ ...applicationDetails, UserId: currentUser.id })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  return (
    <div className={classes.root} id="learner-details">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
        <label style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
            SECTION A
          </label>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <label style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
            LEARNER DETAILS
          </label>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomTextField
            type="text"
            field1='learnerDetails'
            field2='surname'
            placeholder='SURNAME'
            helperText='SURNAME'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='learnerDetails'
            field2='firstNamesFull'
            placeholder='FIRST NAMES(in full)'
            helperText='FIRST NAMES(in full)'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yy"
              id="date-picker-inline"
              helperText='DATE OF BIRTH (dd/mm/yy)'
              views={[ 'date', 'month', 'year']}
              value={applicationDetails.learnerDetails.dateOfBirth}
              onChange={(value: any) =>
                setApplicationDetails({
                    ...applicationDetails,
                    learnerDetails: {
                      ...applicationDetails.learnerDetails,
                      dateOfBirth: isNullOrEmpty(value) ? '' : value.toDateString()
                    }
                })
              }
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomTextField
            type="text"
            field1='learnerDetails'
            field2='idNumber'
            placeholder='ID NUMBER'
            helperText='ID NUMBER'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='learnerDetails'
            field2='homeLanguage'
            placeholder='HOME LANGUAGE'
            helperText='HOME LANGUAGE'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='learnerDetails'
            field2='citizenship'
            placeholder='CITIZENSHIP'
            helperText='CITIZENSHIP'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
            <CustomTextField
              type="text"
              field1='learnerDetails'
              field2='countryOfResidence'
              placeholder='COUNTY OF RESIDENCE'
              helperText='COUNTRY OF RESIDENCE'
              objectDetails={applicationDetails}
              setObjectDetails={setApplicationDetails}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel htmlFor="Citizenship">
              I'm South African
            </InputLabel>
            <Select
              native
              autoWidth
              labelWidth={2}
              value={applicationDetails.learnerDetails.southAfrican}
              onChange={(event: any) =>
                setApplicationDetails({
                  ...applicationDetails,
                  learnerDetails: {
                    ...applicationDetails.learnerDetails,
                    southAfrican: event.target.value
                }})
              }
            >
              <option value='YES'>YES</option>
              <option value='NO'>NO</option>
            </Select>
            {applicationDetails.learnerDetails.southAfrican === 'NO' && (
              <>
                <Grid style={{ marginTop: '2vw'}} item xs={12} sm={12} md={12}>
                <DocumentUpload
                  file={residencyCopy}
                  setFile={setResidencyCopy}
                  filenamePlaceholder="UPLOAD COPY OF RESIDENCY"
                />
                </Grid>
                <Grid style={{ marginTop: '2vw'}} item xs={12} sm={12} md={12}>
                <DocumentUpload
                  file={permitCopy}
                  setFile={setPermitCopy}
                  filenamePlaceholder="UPLOAD COPY OF PERMIT"
                />
                </Grid>
              </>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Address
            helperText='PHYSICAL ADDRESS'
            field1='learnerDetails'
            field2='physicalAddress'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Address
            helperText='POSTAL ADDRESS'
            field1='learnerDetails'
            field2='postalAddress'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel htmlFor="DECEASED PARENT">
              DECEASED PARENT
            </InputLabel>
            <Select
              native
              autoWidth
              labelWidth={2}
              value={applicationDetails.learnerDetails.deceasedParent}
              onChange={(event: any) =>
                setApplicationDetails({
                  ...applicationDetails,
                  learnerDetails: {
                    ...applicationDetails.learnerDetails,
                    deceasedParent: event.target.value
                }})
              }
            >
              <option value='NONE' />
              <option value='MOTHER'>MOTHER</option>
              <option value='FATHER'>FATHER</option>
              <option value='BOTH'>BOTH</option>
            </Select>
            </FormControl>
            <br/>
            <FormControl fullWidth className={classes.formControl}>
            <InputLabel htmlFor="DEXTERITY">
              DEXTERITY
            </InputLabel>
            <Select
              native
              autoWidth
              labelWidth={2}
              value={applicationDetails.learnerDetails.dexterity}
              onChange={(event: any) =>
                setApplicationDetails({
                  ...applicationDetails,
                  learnerDetails: {
                    ...applicationDetails.learnerDetails,
                    dexterity: event.target.value
                }})
              }
            >
              <option value='RIGHT HANDED'>RIGHT HANDED</option>
              <option value='LEFT HANDED'>LEFT HANDED</option>
              <option value='AMDIDEXTOUS'>AMDIDEXTOUS</option>
            </Select>
          </FormControl>
          <br/>
          <CustomTextField
            type="text"
            field1='learnerDetails'
            field2='nameOfPresentSchool'
            placeholder='NAME OF PRESENT SCHOOL'
            helperText='NAME OF PRESENT SCHOOL'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='learnerDetails'
            field2='schoolTelNumber'
            placeholder='SCHOOL TEL. NO'
            helperText='SCHOOL TEL. NO'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Address
            helperText='PRESENT SCHOOL ADDRESS'
            field1='learnerDetails'
            field2='presentSchoolAddress'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel htmlFor="Has the learner repeated a grade">
              HAS THE LEARNER REPEATED A GRADE
            </InputLabel>
            <Select
              native
              autoWidth
              labelWidth={2}
              value={applicationDetails.learnerDetails.hasTheLeanerRepeatedAGrade}
              onChange={(event: any) =>
                setApplicationDetails({
                  ...applicationDetails,
                  learnerDetails: {
                    ...applicationDetails.learnerDetails,
                    hasTheLeanerRepeatedAGrade: event.target.value
                }})
              }
            >
              <option value='TRUE'>YES</option>
              <option value='FALSE'>NO</option>
            </Select>
            </FormControl>
            <br/>
            {applicationDetails.learnerDetails.hasTheLeanerRepeatedAGrade === 'TRUE' && (
              <CustomTextField
                type="text"
                field1='learnerDetails'
                field2='gradeRepeated'
                placeholder='GRADE REPEATED'
                helperText='IF YES WHICH GRADE REPEATED'
                objectDetails={applicationDetails}
                setObjectDetails={setApplicationDetails}
              />
            )}
            <CustomTextField
                type="text"
                field1='learnerDetails'
                field2='socialGrantNumber'
                placeholder='SOCIAL GRANT NUMBER'
                helperText='SOCIAL GRANT NUMBER'
                objectDetails={applicationDetails}
                setObjectDetails={setApplicationDetails}
              />
            <CustomTextField
              type="text"
              field1='learnerDetails'
              field2='modeOfTransport'
              placeholder='MODE OF TRANSPORT'
              helperText='MODE OF TRANSPORT'
              objectDetails={applicationDetails}
              setObjectDetails={setApplicationDetails}
            />
          </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel htmlFor="DO YOU HAVE ANY SIBLINGS IN THIS SCHOOL">
              DO YOU HAVE ANY SIBLINGS IN THIS SCHOOL
            </InputLabel>
            <Select
              native
              autoWidth
              labelWidth={2}
              value={applicationDetails.learnerDetails.doYouHaveAnySiblingsInTheSchool}
              onChange={(event: any) =>
                setApplicationDetails({
                  ...applicationDetails,
                  learnerDetails: {
                    ...applicationDetails.learnerDetails,
                    doYouHaveAnySiblingsInTheSchool: event.target.value
                }})
              }
            >
              <option value='TRUE'>YES</option>
              <option value='FALSE'>NO</option>
            </Select>
          </FormControl>
            <br/>
            {applicationDetails.learnerDetails.doYouHaveAnySiblingsInTheSchool === 'TRUE' && (
              <CustomTextField
                type="text"
                field1='learnerDetails'
                field2='siblingInSchoolGrade'
                placeholder='IN WHICH GRADE'
                helperText='SIBLING IN WHICH GRADE'
                objectDetails={applicationDetails}
                setObjectDetails={setApplicationDetails}
              />
            )}
            
          <CustomTextField
            type="text"
            field1='learnerDetails'
            field2='religionChurchName'
            placeholder='RELIGION(CHURCH NAME)'
            helperText='RELIGION(CHURCH NAME)'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <label style={{ fontWeight: 'bold' }}>
            MEDICAL INFORMATION
          </label>
          <CustomTextField
            type="text"
            field1='learnerDetails'
            field2='health'
            placeholder='HEALTH'
            helperText='HEALTH'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='learnerDetails'
            field2='problemOrAllergies'
            placeholder='PROBLEM/ALLERGIES'
            helperText='PROBLEM/ALLERGIES'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default LeanerDetails;
