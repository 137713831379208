/* eslint-disable react/prop-types */
import "./index.css";

import { FunctionComponent } from 'react'

const About: FunctionComponent = () => {
  return (
    <div className='about'>
        <div  id='about'/>
        <div className="logo-section">
            <div className="logo"/>
            <div className="title">About Albini High School</div>
        </div>
        <div className="paragraphs-section">
        <div className="first-paragraph">Welcome to Albini High School, the first school choice on your new learning journey. Out of all good Special Needs Education schools in Kwa-Zulu Natal, this school is worth a mention. Perhaps you have just moved to -Ntshongweni surrounding areas and need information on registering a new student, Albini High School is your option with great reviews and ratings.</div>
        <div className="second-paragraph">Albini High School is a modern school that embraces the best in South Africa’s changing environment. We are rooted in our proud past, but we gaze confidently into the future, with a keen sense of school community binding learners, staff, and parents in a spirit of excellence.</div>
        </div>
    </div>
  );
};

export default About;
