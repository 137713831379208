/*
import '@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css';
import "@patternfly/react-core/dist/styles/base.css";
import "react-toastify/dist/ReactToastify.css";
import './index.css';
*/

import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from 'home';
import Store from 'store'
import RegistrationStore from 'registration/store'
import Applications from 'applications';
import Login from 'authentication/login';
import Signup from 'authentication/signup';
import WhatsAppFloatingBtn from 'shared/components/WhatsAppFloatingBtn';
import PasswordReset from 'authentication/password-reset';
import ApplicationStatistics from 'applications/stats';
import MyApplication from 'applications/application/application';
import PhoneNumberVerification from 'authentication/phone-number-verification';
import FirstReportGrades from 'applications/application/FirstReportGrades/first-report-grades';
import ApplicationAttachments from 'applications/application/ApplicationAttachments/attachments';
import SecondReportGrades from 'applications/application/SecondReportGrades/second-report-grades';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.Fragment>
    <Store>
      <RegistrationStore>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/applications" element={<Applications />} />
          <Route path="/password-reset" element={<PasswordReset />} />
          <Route path="/application/:id" element={<MyApplication />} />
          <Route path="/attachments/:id" element={<ApplicationAttachments />} />
          <Route path="/applications-stats" element={<ApplicationStatistics />} />
          <Route path="/first-report-grades/:id" element={<FirstReportGrades />} />
          <Route path="/second-report-grades/:id" element={<SecondReportGrades />} />
          <Route path="/phone-number-verification" element={<PhoneNumberVerification />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
      <WhatsAppFloatingBtn />
      </RegistrationStore>
    </Store>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
