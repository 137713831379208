const Address = {
  line1: "",
  line2: "",
  line3: "",
  postalCode: "",
  createdAt: new Date(),
}
export const applicationDetailsInitialState = {
    studentDetails: {
        firstName: "",
        lastName: "",
        iDORBirthCertificateNumber: "",
        residentialAddress: Address,
        postalAddress: Address,
        telHome: "",
        cell: "",
        fax: "",
        email: "",
        title: "",
        race: "",
        otherRace: "",
        gender: "",
        disabilitiesOrMedicalConditionAffectStudies: "",
        natureOfDisabilityOrMedicalCondition: "",
        applicantIDORBirthCertificateFile: null,
        applicantIDORBirthCertificateFileName: ''
    },
    grade: {
      id: 0,
      name: ''
    },
    parentOrGuardianDetails: {
        relationship: "",
        idNumber: "",
        telWork: "",
        telHome: "",
        cell: "",
        email: "",
        residentialAddress: Address,
        postalAddress: Address,
        parentOrGuardianIDORPassportFile: null,
        parentOrGuardianIDORPassportFileName: ''
    },
    academicHistory: {
        lastSchoolAttended: "",
        year: "",
        country: "",
        aggregate: "",
        latestGradeLevelComplete: "",
        applicantLatestSchoolReportFile: null,
        applicantLatestSchoolReportFileName: ''
    },
    declarationDetails: {
        applicantDeclaration: {
          fullName: "",
          idOrBirthCertificateNumber: "",
          applicantSignature: {
            initials: "",
            date: new Date().toDateString()
          },
          witnessSignature: {
            initials: "",
            date: new Date().toDateString()
          },
          parentOrGuardianSignature: {
            initials: "",
            date: new Date().toDateString()
          }
        },
        benifactorDeclaration: {
          fullName: "",
          iDORPassportNumber: "",
          signature: {
            initials: "",
            date: new Date().toDateString()
          }
        }
    }
}


export const applicationFormDetails = {
  learnerDetails: {
      surname: '',
      firstNamesFull: '',
      dateOfBirth: new Date(),
      idNumber: '',
      homeLanguage: '',
      citizenship: '',
      residencyCopyURL: '',
      permitCopyURL: '',
      residencyCopyFile: null,
      permitCopyFile: null,
      countryOfResidence: '',
      physicalAddress: Address,
      postalAddress: Address,
      deceasedParent: 'NONE',
      dexterity: '',
      nameOfPresentSchool: '',
      presentSchoolAddress: Address,
      schoolTelNumber: '',
      hasTheLeanerRepeatedAGrade: 'FALSE',
      gradeRepeated: '',
      socialGrantNumber: '',
      modeOfTransport: '',
      religionChurchName: '',
      doYouHaveAnySiblingsInTheSchool: 'FALSE',
      siblingInSchoolGrade: '',
      health: '',
      problemOrAllergies: '',
      createdAt: new Date(),
      southAfrican: 'YES'
  },
  mothersDetails : {
    title: '',
    initials: '',
    surname: '',
    firstNameInFull: '',
    homeLanguage: '',
    race: '',
    idNumber: '',
    residentialAddress: Address,
    postalAddress: Address,
    maritalStatus: '',
    cellOrEmergencyNumber: '',
    occupation: '',
    nameOfEmployerOrBusiness: '',
    relationshipToLearner: '',
    createdAt: new Date(),
  },
  fathersDetails : {
    surname: '',
    firstNameInFull: '',
    homeLanguage: '',
    race: '',
    idNumber: '',
    residentialAddress: Address,
    postalAddress: Address,
    occupation: '',
    maritalStatus: '',
    cellOrEmergencyNumber: '',
    nameOfEmployerOrBusiness: '',
    whoDoesThelearnerLiveWith: '',
    relationshipToLearner: '',
    createdAt: new Date(),
  },
  guardian : {
    surname: '',
    firstNameInFull: '',
    homeLanguage: '',
    race: '',
    idNumber: '',
    residentialAddress: Address,
    postalAddress: Address,
    cellOrEmergencyNumber: '',
    occupation: '',
    nameOfEmployer: '',
    relationshipToLearner: '',
    nameOfParentOrGuardian: '',
    signatureOfParentOrGuardian: '',
    date: new Date(),
    createdAt: new Date(),
  },
  declaration : {
    thisFormSignedAt: '',
    thisFormSignedOnDay: '',
    thisFormSignedOnMonth: '',
    signatureOfLearner: '',
    nameAndSurnameOfGuardian1: '',
    signatureOfParentOrGuardian1: '',
    nameOfWitness1: '',
    signatureOfWitness1: '',
    nameAndSurnameOfGuardian2: '',
    signatureOfParentOrGuardian2: '',
    nameOfWitness2: '',
    signatureOfWitness2: '',
    createdAt: new Date(),
  },
  grade: {
    id: 0,
    name: '',
    createdAt: new Date()
  }
}


