import { useEffect, FunctionComponent, useMemo, useContext } from 'react';
import { useParams } from 'react-router-dom';
import React from 'react';
import HeaderFooter from 'home/sections/header-footer';
import { CircularProgress } from '@material-ui/core';
import { ApplicationForm, UseApi } from 'api/applications/types';
import { MyApplicationContext } from 'store';
import ApplicationTabs from './application-tabs/application-tabs';

type ApplicationComponentWrapperProps = {
  children: JSX.Element
};

const ApplicationComponentWrapper: FunctionComponent<ApplicationComponentWrapperProps> = ({
  children
}) => {
  const { id } = useParams();
  var currentUserId: any = Number(id)
  const getMyApplication = useContext<UseApi>(MyApplicationContext);

  useEffect(() => {
    if(!getMyApplication.data.id) getMyApplication.execute(currentUserId)
  }, [])

  const application: ApplicationForm = useMemo(() => getMyApplication.data, [getMyApplication.data]);

  return (
      <HeaderFooter isHomePage={false}>
        <div className='my-application'>
          <div className='background-banner' />
          <div className='main-section' >
            {getMyApplication.inProgress
              ? <div className='spinner'> <CircularProgress color="inherit" size={50} /> </div>
              : !application.id ? <div className='no-applications'> No application found </div> : (
                <React.Fragment>
                  <ApplicationTabs grade={application.grade.name}/>
                  {children}
                </React.Fragment>
              )
            }
          </div>
        </div>
      </HeaderFooter>
  );
};

export default ApplicationComponentWrapper;