import './navbar.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBank } from '@fortawesome/free-solid-svg-icons'
import { openInNewTab } from "shared/utils/actions";

export default function Navbar() {

  return (
    <nav className='top-navbar'>
      <div className='left-section'>
        <div className='phone-number'>
          <FontAwesomeIcon color='white' icon={faBank} size='sm' /> FNB ACCOUNT: 5940 1149 313
        </div>
        <div className='branch-code'>
          <FontAwesomeIcon color='white' icon={faBank} size='sm' /> BRANCH CODE: 223 726
        </div>
        <div className='branch-code'>
          <FontAwesomeIcon color='white' icon={faBank} size='sm' /> BRANCH NAME: HILLCREST
        </div>
        <div className='branch-code'>
          APPLICATIONS OPEN: 15 APRIL 2024
        </div>
        <div className='branch-code'>
          APPLICATIONS CLOSE: 31 MAY 2024
        </div>
      </div>
      <div 
        className='right-section'
        onClick={() => openInNewTab('https://focusmentorship.com')}
      >
          <div className='item last-item'>Focus Mentorship</div>
      </div>
    </nav>
  )
}