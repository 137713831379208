import MainBanner from './sections/main-banner';
import HistoryAndLegacy from './sections/history-and-legacy';
import About from './sections/about';
import StatsSection from './sections/stats-section';
import SocialsSection from './sections/socials';
import Testimonials from './sections/testimonials';
import GetInTouch from './sections/apply-now';
import Contact from './sections/contact';
import RegistrationModal from '../registration';
import HeaderFooter from './sections/header-footer';

export default function App() {
  return ( 
    <HeaderFooter isHomePage>
      <>
        <MainBanner /> 
        <HistoryAndLegacy />  
        <About />
        <StatsSection />
        <SocialsSection />
        <Testimonials />
        <GetInTouch />
        <RegistrationModal />
        <Contact />
      </>
    </HeaderFooter>
  );
}