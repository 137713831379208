import React, { useContext, FunctionComponent } from 'react';
import { Wizard } from '@patternfly/react-core';
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

import Grade from './sections/grade';
import Guardian from './sections/guardian';
import Declaration from './sections/declaration';
import FatherDetails from './sections/fathers-details';
import MothersDetails from './sections/mothers-details';
import LearnerDetails from './sections/learner-details';
import { ApplicationDetailsContext, RegistrationModalVisibilityContext } from './store';
import applicationDetailsValidation from './shared/utils/validations';

type RegistrationModalProps = {
  inProgress: boolean;
  onSubmitRegistration: () => void;
  onSubmitRegistrationWithAttachments: () => void;
}

const RegistrationModal: FunctionComponent<RegistrationModalProps> = ({
  inProgress,
  onSubmitRegistration,
  onSubmitRegistrationWithAttachments
}) => {
  const [application] = useContext(ApplicationDetailsContext);
  const[isVisible, setVisible] = useContext(RegistrationModalVisibilityContext)

  const steps = [
    { name: 'Learner Details', component: <LearnerDetails /> },
    { name: 'Select Grade', component: <Grade /> },
    { name: 'Mothers Details', component: <MothersDetails />, },
    { name: 'Fathers Details', component: <FatherDetails />, },
    { name: 'Guardian Details', component: <Guardian />, },
    { 
      name: 'Declaration', 
      component: inProgress ? 
        <Grid justifyContent='center' container spacing={3} style={{ marginTop: '100px'}}>
            <CircularProgress size={100} />
        </Grid>
       : <Declaration />, 
      nextButtonText: 'Finish',
      isFinishedStep: inProgress
    }
  ];

  return (
      <Wizard
        title='Online Registration (Application Fee R100)'
        description="Albini High School"
        descriptionComponent="div"
        steps={steps}
        onClose={() => setVisible(false)}
        isOpen={isVisible}
        onSave={() => {
          if(applicationDetailsValidation(application)) {
            if(application.learnerDetails.southAfrican === 'NO') onSubmitRegistrationWithAttachments()
            else onSubmitRegistration()
          }
        }}
      />
  );
}

export default RegistrationModal;