import './index.css'

import { FunctionComponent, useContext } from "react";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { ApplicationsContext, MyApplicationContext } from 'store';
import { selectRows } from './selectors';
import { columns } from './constants';
import HeaderFooter from 'home/sections/header-footer';
import { ApplicationForm, UseApi } from 'api/applications/types';
import { useNavigate } from 'react-router-dom';

type ApplicationsProps = {
  inProgress: boolean;
}

const Applications: FunctionComponent<ApplicationsProps> = ({
  inProgress
}) => {
  const navigate = useNavigate();
  const [applications] = useContext(ApplicationsContext);
  const getMyApplication = useContext<UseApi>(MyApplicationContext);

  return (
    <HeaderFooter isHomePage={false}>
      <div className='applications'>
        <div className='background-banner' />
        <div className='main-section' >
          <div className='grid-container'>
            <div style={{ height: 500 }}>
              <DataGrid 
                rows={selectRows(applications)} 
                columns={columns.filter(c => c.field !== 'id')} 
                pagination 
                loading={inProgress} 
                onCellClick={(cell) => {
                  const app = applications.find((app: ApplicationForm) => app.id === cell.id);
                  getMyApplication.setData(app);
                  navigate(`/application/${app.userId}`)
                }}
                localeText={{
                  noRowsLabel: 'No applications found',
                }}
                slots={{ toolbar: GridToolbar }}
              />
            </div>
          </div>
        </div>
      </div>
    </HeaderFooter>
  );
}

export default Applications;