/* eslint-disable react/prop-types */
import { FunctionComponent, useContext, useEffect } from "react";
import { Grid } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import Address from "registration/shared/components/address";
import useStyles from "registration/shared/utils/use-styles";
import CustomTextField from "registration/shared/components/dimensional-text-fields/2D-text-field";
import { ApplicationDetailsContext } from "registration/store";
import { CurrentUserContext } from "store";
import { isEmpty, isNullOrEmpty } from "shared/utils/string";

const Guardian: FunctionComponent = () => {
  const classes = useStyles();
  const [currentUser] = useContext(CurrentUserContext);
  const [applicationDetails, setApplicationDetails] = useContext(ApplicationDetailsContext)  
 
  useEffect(() => document.getElementById('guardian-details')!.scrollIntoView(), [])
  useEffect(() => {
    if(!isEmpty(currentUser)) setApplicationDetails({ ...applicationDetails, UserId: currentUser.id })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  return (
    <div className={classes.root} id="guardian-details">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <label style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
            SECTION B
          </label>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <label style={{ fontWeight: 'bold' }}>
            PARTICULARS OF PARENT/GAURDIAN
          </label>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomTextField
            type="text"
            field1='guardian'
            field2='surname'
            placeholder='SURNAME'
            helperText='SURNAME'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='guardian'
            field2='firstNameInFull'
            placeholder='FIRST NAME IN FULL'
            helperText='FIRST NAME IN FULL'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='guardian'
            field2='homeLanguage'
            placeholder='HOME LANGUAGE'
            helperText='HOME LANGUAGE'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomTextField
            type="text"
            field1='guardian'
            field2='race'
            placeholder='RACE'
            helperText='RACE'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='guardian'
            field2='idNumber'
            placeholder='IDENTITY NUMBER'
            helperText='IDENTITY NUMBER'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='guardian'
            field2='cellOrEmergencyNumber'
            placeholder='CELL NO/EMERGENCY NO.'
            helperText='CELL NO/EMERGENCY NO.'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Address
            helperText='RESIDENTIAL ADDRESS'
            field1='guardian'
            field2='residentialAddress'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Address
            helperText='POSTAL ADDRESS'
            field1='guardian'
            field2='postalAddress'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomTextField
            type="text"
            field1='guardian'
            field2='occupation'
            placeholder='OCCUPATION'
            helperText='OCCUPATION'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='guardian'
            field2='nameOfEmployer'
            placeholder='NAME OF EMPLOYER'
            helperText='NAME OF EMPLOYER'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='guardian'
            field2='relationshipToLearner'
            placeholder='RELATIONSHIP TO LEARNER'
            helperText='RELATIONSHIP TO LEARNER'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <label className={"align-text-center"}>
            I hereby declare that to the best of my knowledge the above information as supplied is accurate and correct.
          </label>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomTextField
            type="text"
            field1='guardian'
            field2='nameOfParentOrGuardian'
            placeholder='Name of Parent/Guardian'
            helperText='Name of Parent/Guardian'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='guardian'
            field2='signatureOfParentOrGuardian'
            placeholder='Signature of Parent/Guardian (Initials)'
            helperText='Signature of Parent/Guardian (Initials)'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              id="date-picker-inline"
              helperText='Date'
              views={[ 'date', 'month', 'year']}
              value={applicationDetails.guardian.date}
              onChange={(value: any) =>
                setApplicationDetails({
                    ...applicationDetails,
                    guardian: {
                      ...applicationDetails.guardian,
                      date: isNullOrEmpty(value) ? '' : value.toDateString()
                    }
                })
              }
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    </div>
  );
};

export default Guardian;
