/* eslint-disable react/prop-types */
import "./index.css";

import { FunctionComponent, useContext } from 'react';
import { isEmpty } from "shared/utils/string";
import { useNavigate } from "react-router-dom";
import { CurrentUserContext } from "store";
import { RegistrationModalVisibilityContext } from "registration/store";

const ApplyNow: FunctionComponent = () => {
  const navigate = useNavigate();
  const [currentUser] = useContext(CurrentUserContext);
  const [, setRegistrationModalVisible] = useContext(RegistrationModalVisibilityContext);
  return (
    <div id='apply-now' className='get-intouch'>
        <div className="title">Is Your Child A Top Achiever?</div>
        <div className="paragraph">If you are considering Albini High School for your Child, click below to begin online application.</div>
        <div 
          className="button"
          onClick={() => {
            if(isEmpty(currentUser)) navigate('/login');
            else setRegistrationModalVisible(true)
          }}
        >APPLY HERE</div>
    </div>
  );
};

export default ApplyNow;
