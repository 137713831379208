import './index.css'

import { toast } from 'react-toastify';
import { CircularProgress, TextField } from "@material-ui/core";
import { FunctionComponent, useContext, useMemo, useState } from "react";

import Wrapper from '../Wrapper';
import { MyApplicationContext } from 'store';
import useApi from 'shared/utils/react_use_api';
import * as applicationsApi from "api/applications";
import { isNullOrEmpty } from "shared/utils/string";
import NotifyFailure from 'shared/utils/notify-failure';
import { ApplicationForm, SubjectMark, UseApi } from 'api/applications/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';

const addSubjectInitalValue = {
  subjectGrade: null,
  applicationFormId: 0,
  reportDescription: '',
  subjectDescription: ''
}

type SubjectMarkType = {
  subjectGrade: number | null;
  applicationFormId: number;
  subjectDescription: string; 
  reportDescription: string;
}

type SubjectMarkDeleteResponse = {
  subjectMark: SubjectMarkType;
  applicationForm: ApplicationForm;
}


const FirstReportGradesComponent: FunctionComponent = () => {
  const getMyApplication = useContext<UseApi>(MyApplicationContext);
  const [subjectMark, setSubjectMark] = useState<SubjectMarkType>(addSubjectInitalValue)

  const application: ApplicationForm = useMemo(() => getMyApplication.data, [getMyApplication.data]);

  const addSubject = useApi({
    action: (subjectMark: SubjectMark) => applicationsApi.addSubjectMark(subjectMark),
    defer: true,
    onSuccess: (application: ApplicationForm) => {
      getMyApplication.setData(application);

      toast.success(`${subjectMark.subjectDescription} with mark ${subjectMark.subjectGrade} added successfully`);

      setSubjectMark(addSubjectInitalValue)
    },
    onError: (error: any) => NotifyFailure(error.response, error.message)
  }, [])

  const deleteSubject = useApi({
    action: (subjectMark: SubjectMark) => applicationsApi.deleteSubjectMark(subjectMark),
    defer: true,
    onSuccess: (smdr: SubjectMarkDeleteResponse) => {
      getMyApplication.setData(smdr.applicationForm);

      toast.success(`${smdr.subjectMark.subjectDescription} with mark ${smdr.subjectMark.subjectGrade} delete successfully`);

      setSubjectMark(addSubjectInitalValue)
    },
    onError: (error: any) => NotifyFailure(error.response, error.message)
  }, [])

  const getReportDescription = () => {
    if (application.grade && application.grade.name === 'Grade 8') return 'Grade 6 Final Report Marks'
    if (application.grade && application.grade.name === 'Grade 9') return 'Grade 7 Final Report Marks'
    if (application.grade && application.grade.name === 'Grade 10') return 'Grade 8 Final Report Marks'
    if (application.grade && application.grade.name === 'Grade 11') return 'Grade 9 Final Report Marks'
    if (application.grade && application.grade.name === 'Grade 12') return 'Grade 10 Final Report Marks'
  }

  const isAddBtnDisabled = isNullOrEmpty(subjectMark.subjectDescription) || isNullOrEmpty(`${subjectMark.subjectGrade}`) || subjectMark.subjectGrade! < 0

  const onAddSubjectMark = () => {
    if (!isAddBtnDisabled) {
      const data: any = {
        ...subjectMark,
        applicationFormId: application.id,
        reportDescription: getReportDescription()
      }

      addSubject.execute(data)
    }
  }

  const Component = () => (
    <div style={{ textAlign: 'center' }}>
      <div className='section-header'>{getReportDescription()}</div>

      <div className='subject-and-grade-inputs-container'>
        <div className='subject-and-grade-inputs'>
          <TextField
            required
            fullWidth
            id="subject-name"
            label="Subject Name"
            name="subject name"
            value={subjectMark.subjectDescription}
            className='subject-and-grade-input'
            onChange={e => setSubjectMark({
              ...subjectMark,
              subjectDescription: e.currentTarget.value
            })}
          />
          <TextField
            required
            fullWidth
            name="mark"
            label="Mark"
            type="number"
            id="mark"
            value={subjectMark.subjectGrade || ''}
            className='subject-and-grade-input'
            onChange={e => setSubjectMark({
              ...subjectMark,
              subjectGrade: Number(e.currentTarget.value)
            })}
          />
        </div>
        <div
          className={isAddBtnDisabled ? 'add-subject-btn' : 'add-subject-btn-active'}
          onClick={() => onAddSubjectMark()}
        >
          {addSubject.inProgress || deleteSubject.inProgress ? <CircularProgress color="inherit" /> : 'ADD SUBJECT MARK'}
        </div>

        <div className='subject-mark-container'>
          {application.subjectMarks && application.subjectMarks.filter(sm => sm.reportDescription === getReportDescription()).map((sm: any, index) => (
            <div className='subject-mark' key={index}>
              <div className='subject-desction'>{sm.subjectDescription}</div>
              <div className='subject-grade-remove'>
                <div className='subject-grade'>{sm.subjectGrade}</div>
                <FontAwesomeIcon
                  size='sm'
                  color='red'
                  icon={faMinusCircle}
                  onClick={() => deleteSubject.execute(sm)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )

  return (
    <Wrapper>
      {application && Component()}
    </Wrapper>
  )
}

export default FirstReportGradesComponent;