import React, { FunctionComponent } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from "@material-ui/core/CircularProgress";

import { User } from 'api/authentication/types';

const theme = createTheme({
  /*
  palette: {
    primary: {
      main: '#f5b714',
      contrastText: "#fff"
    }
  }
  */
});

type LoginProps = {
  user: User;
  inProgress: boolean;
  onLogin: (user: User) => void;
}

const Login: FunctionComponent<LoginProps> = ({
  user,
  inProgress,
  onLogin
}) => {
  const navigate = useNavigate();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data: any = new FormData(event.currentTarget);
    onLogin({
      ...user,
      phoneNumber: data.get('phoneNumber'),
      password: data.get('password'),
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${require("../../assets/images/login-page/banner.jpeg")})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar 
              alt="Remy Sharp"
              sx={{ width: 100, height: 100 }}
              src={require('../../assets/logo-square.png')} 
            />
            <Typography component="h1" variant="h5">
              Sign In
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="phoneNumber"
                  label="Phone Number"
                  name="phoneNumber"
                  autoComplete="phoneNumber"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
               {inProgress ? <CircularProgress color="inherit" /> : 'Sign In'}
            </Button>
            <Grid container justifyContent="space-between">
              <Grid item onClick={() => navigate('/signup')}>
                <Link href="#" variant="body2">
                  Create Account
                </Link>
              </Grid>
              <Grid item onClick={() => navigate('/password-reset')}>
                <Link href="#" variant="body2">
                  Password Reset
                </Link>
              </Grid>
            </Grid>
            <Typography variant="body2" color="text.primary" align="center" sx={{ mt: 5 }}>
              <RouterLink to={"/"}>
                Home
              </RouterLink>
            </Typography>
          </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Login;