import { toast } from "react-toastify";
import { isNullOrEmpty } from "shared/utils/string";

export const applicationDetailsValidation = application => {
  if (!leanerDetailsValidation(application.learnerDetails)) return false;
  else if (!gradeDetailsValidation(application.grade)) return false;
  else if (!guardianValidation(application.guardian)) return false;
  else if (!declarationValidation(application.declaration)) return false;

  return true;
};

export const gradeDetailsValidation = grade => {
  if (isNullOrEmpty(grade.name)) {
    toast.error("Select Grade: Please choose grade.");
    return false;
  }
  return true;
}

export const leanerDetailsValidation = learnerDetails => {
  if (isNullOrEmpty(learnerDetails.surname)) {
    toast.error("Learner Details: Please enter surname.");
    return false;
  } else if (isNullOrEmpty(learnerDetails.firstNamesFull)) {
    toast.error("Learner Details: Please enter first names.");
    return false;
  } else if (isNullOrEmpty(learnerDetails.dateOfBirth)) {
    toast.error("Learner Details: Please enter date of birth.");
    return false;
  } else if (isNullOrEmpty(learnerDetails.idNumber)) {
    toast.error("Learner Details: Please enter ID number.");
    return false;
  } else if (isNullOrEmpty(learnerDetails.homeLanguage)) {
    toast.error("Learner Details: Please enter home language.");
    return false;
  } else if (isNullOrEmpty(learnerDetails.citizenship)) {
    toast.error("Learner Details: Please enter citizenship.");
    return false;
  } else if (isNullOrEmpty(learnerDetails.residencyCopyFile) && learnerDetails.southAfrican === "NO") {
    toast.error("Learner Details: Please upload residency copy");
    return false;
  } else if (isNullOrEmpty(learnerDetails.permitCopyFile) && learnerDetails.southAfrican === "NO") {
    toast.error("Learner Details: Please upload permit copy");
    return false;
  } else if (isNullOrEmpty(learnerDetails.countryOfResidence)) {
    toast.error("Learner Details: Please enter coutry of residence.");
    return false;
  } else if (isNullOrEmpty(learnerDetails.nameOfPresentSchool)) {
    toast.error("Learner Details: Please enter name of present school");
    return false;
  } else if (learnerDetails.hasTheLeanerRepeatedAGrade === true && isNullOrEmpty(learnerDetails.gradeRepeated)) {
    toast.error("Learner Details: Please enter grade repeated");
    return false;
  }
  return true;
};

const mothersDetailsValidation = mothersDetails => {
  if (isNullOrEmpty(mothersDetails.title)) {
    toast.error("Mothers Details: Please enter title.");
    return false;
  } else if (isNullOrEmpty(mothersDetails.initials)) {
    toast.error("Mothers Details: Please enter initials.");
    return false;
  } else if (isNullOrEmpty(mothersDetails.surname)) {
    toast.error("Mothers Details: Please enter surname.");
    return false;
  } else if (isNullOrEmpty(mothersDetails.firstNameInFull)) {
    toast.error("Mothers Details: Please enter first names");
    return false;
  } else if (isNullOrEmpty(mothersDetails.homeLanguage)) {
    toast.error("Mothers Details: Please enter home language");
    return false;
  } else if (isNullOrEmpty(mothersDetails.race)) {
    toast.error("Mothers Details: Please enter race");
    return false;
  } else if (isNullOrEmpty(mothersDetails.idNumber)) {
    toast.error("Mothers Details: Please enter id number");
    return false;
  } else if (isNullOrEmpty(mothersDetails.cellOrEmergencyNumber)) {
    toast.error("Mothers Details: Please enter cell phone number or emergency number");
    return false;
  } else if (isNullOrEmpty(mothersDetails.relationshipToLearner)) {
    toast.error("Mothers Details: Please enter relationship to learner");
    return false;
  }

  return true;
};

const fathersDetailsValidation = fathersDetails => {
  if (isNullOrEmpty(fathersDetails.surname)) {
    toast.error("Fathers Details: Please enter surname.");
    return false;
  } else if (isNullOrEmpty(fathersDetails.firstNameInFull)) {
    toast.error("Fathers Details: Please enter first names");
    return false;
  } else if (isNullOrEmpty(fathersDetails.homeLanguage)) {
    toast.error("Fathers Details: Please enter home language");
    return false;
  } else if (isNullOrEmpty(fathersDetails.race)) {
    toast.error("Fathers Details: Please enter race");
    return false;
  } else if (isNullOrEmpty(fathersDetails.idNumber)) {
    toast.error("Fathers Details: Please enter id number");
    return false;
  } else if (isNullOrEmpty(fathersDetails.cellOrEmergencyNumber)) {
    toast.error("Fathers Details: Please enter cell phone number or emergency number");
    return false;
  } else if (isNullOrEmpty(fathersDetails.whoDoesThelearnerLiveWith)) {
    toast.error("Fathers Details: Please enter who learner lives with");
    return false;
  } else if (isNullOrEmpty(fathersDetails.relationshipToLearner)) {
    toast.error("Fathers Details: Please enter relationship to learner");
    return false;
  }

  return true;
};

const guardianValidation = guardianDetails => {
  if (isNullOrEmpty(guardianDetails.surname)) {
    toast.error("Guardian: Please enter surname.");
    return false;
  } else if (isNullOrEmpty(guardianDetails.firstNameInFull)) {
    toast.error("Guardian: Please enter first names");
    return false;
  } else if (isNullOrEmpty(guardianDetails.homeLanguage)) {
    toast.error("Guardian: Please enter home language");
    return false;
  } else if (isNullOrEmpty(guardianDetails.race)) {
    toast.error("Guardian: Please enter race");
    return false;
  } else if (isNullOrEmpty(guardianDetails.idNumber)) {
    toast.error("Guardian: Please enter id number");
    return false;
  } else if (isNullOrEmpty(guardianDetails.relationshipToLearner)) {
    toast.error("Guardian: Please enter relationship to learner");
    return false;
  } else if (isNullOrEmpty(guardianDetails.nameOfParentOrGuardian)) {
    toast.error("Guardian: Please enter name of parent or guardian");
    return false;
  } else if (isNullOrEmpty(guardianDetails.signatureOfParentOrGuardian)) {
    toast.error("Guardian: Please enter parent or guardian signature");
    return false;
  }

  return true;
};

const declarationValidation = declarationDetails => {
  if (isNullOrEmpty(declarationDetails.thisFormSignedAt)) {
    toast.error("Declaration: Please enter form signed at details.");
    return false;
  } else if (isNullOrEmpty(declarationDetails.thisFormSignedOnDay)) {
    toast.error("Declaration: Please enter day form signed");
    return false;
  } else if (isNullOrEmpty(declarationDetails.thisFormSignedOnMonth)) {
    toast.error("Declaration: Please enter month form signed");
    return false;
  } else if (isNullOrEmpty(declarationDetails.signatureOfLearner)) {
    toast.error("Declaration: Please enter signature of learner");
    return false;
  } else if (isNullOrEmpty(declarationDetails.nameAndSurnameOfGuardian1)) {
    toast.error("Declaration: Please enter name and surname of first guardian");
    return false;
  } else if (isNullOrEmpty(declarationDetails.signatureOfParentOrGuardian1)) {
    toast.error("Declaration: Please enter signature of first parent or guardian");
    return false;
  } else if (isNullOrEmpty(declarationDetails.nameOfWitness1)) {
    toast.error("Declaration: Please enter name of witness");
    return false;
  } else if (isNullOrEmpty(declarationDetails.signatureOfWitness1)) {
    toast.error("Declaration: Please enter witness signature");
    return false;
  } else if (isNullOrEmpty(declarationDetails.nameAndSurnameOfGuardian2)) {
    toast.error("Declaration: Please enter name and surname of second guardian");
    return false;
  } else if (isNullOrEmpty(declarationDetails.signatureOfParentOrGuardian2)) {
    toast.error("Declaration: Please enter signature of second parent or guardian");
    return false;
  } else if (isNullOrEmpty(declarationDetails.nameOfWitness2)) {
    toast.error("Declaration: Please enter name of witness");
    return false;
  } else if (isNullOrEmpty(declarationDetails.signatureOfWitness2)) {
    toast.error("Declaration: Please enter witness signature");
    return false;
  }

  return true;
};


export default applicationDetailsValidation;
