import { GridColDef } from '@mui/x-data-grid';

export const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'date', headerName: 'Date', width: 100, type: 'date' },
    { field: 'gradeName', headerName: 'Grade', width: 150 },
    { field: 'firstName', headerName: 'First Name', width: 150 },
    { field: 'lastName', headerName: 'Last Name', width: 150 },
    { field: 'reportsAverage', headerName: 'Reports Average', width: 200 },
    { field: 'applicationStatus', headerName: 'Application Status', width: 250 },
    { field: 'applicationFeePaid', headerName: 'Application Fee Paid', width: 200, type: 'boolean' }
  ];
  