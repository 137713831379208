/* eslint-disable react/prop-types */
import { FunctionComponent, useContext, useEffect } from "react";
import { Grid } from "@material-ui/core";

import Address from "registration/shared/components/address";
import useStyles from "registration/shared/utils/use-styles";
import CustomTextField from "registration/shared/components/dimensional-text-fields/2D-text-field";
import { ApplicationDetailsContext } from "registration/store";
import { CurrentUserContext } from "store";
import { isEmpty } from "shared/utils/string";

const MothersDetails: FunctionComponent = () => {
  const classes = useStyles();
  const [currentUser] = useContext(CurrentUserContext);
  const [applicationDetails, setApplicationDetails] = useContext(ApplicationDetailsContext)

  useEffect(() => document.getElementById('mother-details')!.scrollIntoView(), [])
  useEffect(() => {
    if (!isEmpty(currentUser)) setApplicationDetails({ ...applicationDetails, UserId: currentUser.id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  return (
    <div className={classes.root} id="mother-details">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <label style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
            SECTION B
          </label>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <label style={{ fontWeight: 'bold' }}>
            PARTICULARS OF PARENT/GAURDIAN
          </label>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomTextField
            type="text"
            field1='mothersDetails'
            field2='title'
            placeholder='TITLE'
            helperText='TITLE'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomTextField
            type="text"
            field1='mothersDetails'
            field2='initials'
            placeholder='INITIALS'
            helperText='INITIALS'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomTextField
            type="text"
            field1='mothersDetails'
            field2='surname'
            placeholder='SURNAME'
            helperText='SURNAME'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='mothersDetails'
            field2='firstNameInFull'
            placeholder='FIRST NAME(in full)'
            helperText='FIRST NAME(in full)'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='mothersDetails'
            field2='homeLanguage'
            placeholder='HOME LANGUAGE'
            helperText='HOME LANGUAGE'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomTextField
            type="text"
            field1='mothersDetails'
            field2='race'
            placeholder='RACE'
            helperText='RACE'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='mothersDetails'
            field2='idNumber'
            placeholder='IDENTITY NUMBER'
            helperText='IDENTITY NUMBER'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='mothersDetails'
            field2='maritalStatus'
            placeholder='MARITAL STATUS'
            helperText='MARITAL STATUS'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Address
            helperText='RESIDENTIAL ADDRESS'
            field1='mothersDetails'
            field2='residentialAddress'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Address
            helperText='POSTAL ADDRESS'
            field1='mothersDetails'
            field2='postalAddress'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomTextField
            type="text"
            field1='mothersDetails'
            field2='cellOrEmergencyNumber'
            placeholder='CELL NO/EMERGENCY NO.'
            helperText='CELL NO/EMERGENCY NO.'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='mothersDetails'
            field2='occupation'
            placeholder='OCCUPATION'
            helperText='OCCUPATION'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomTextField
            type="text"
            field1='mothersDetails'
            field2='nameOfEmployerOrBusiness'
            placeholder='NAME OF EMPLOYER OR BUSINESS'
            helperText='NAME OF EMPLOYER OR BUSINESS'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
          <CustomTextField
            type="text"
            field1='mothersDetails'
            field2='relationshipToLearner'
            placeholder='RELATIONSHIP TO LEARNER'
            helperText='RELATIONSHIP TO LEARNER'
            objectDetails={applicationDetails}
            setObjectDetails={setApplicationDetails}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MothersDetails;
