import './index.css'

import { FunctionComponent, useContext } from "react";
import { toast } from "react-toastify";
import { CircularProgress } from '@material-ui/core';

import useApi from 'shared/utils/react_use_api';
import * as registrationApi from "api/applications";
import * as applicationsApi from "api/applications";
import NotifyFailure from 'shared/utils/notify-failure';
import { ApplicationsContext, CurrentUserContext, MyApplicationContext } from 'store';
import { RegistrationComponentsContext } from 'registration/store';
import { ApplicationForm, RegistrationComponents, UseApi } from 'api/applications/types';
import FormControlSelect from "registration/shared/components/dimensional-form-control-select/1D-form-control-select-pair-no-label";

type ApplicationsProps = {
  application: ApplicationForm;
}

const ApplicationDetailsModal: FunctionComponent<ApplicationsProps> = ({
  application
}) => {
  const [currentUser] = useContext(CurrentUserContext);
  const getMyApplication = useContext<UseApi>(MyApplicationContext);
  const [applications, setApplications] = useContext(ApplicationsContext)
  const [applicationComponents, setRegistrationComponents] = useContext(RegistrationComponentsContext);

  const getRegistrationComponents = useApi({
    action: () => registrationApi.getRegistrationComponents(),
    condition: !applicationComponents.applicationStatuses,
    onSuccess: (registrationComponents: RegistrationComponents) => setRegistrationComponents(registrationComponents),
    onError: (error: any) => NotifyFailure(error.response, error.message)
  }, [])

  const updateApplicationStatus = useApi({
    action: (application: ApplicationForm) => applicationsApi.updateApplicationStatus(application),
    initialValue: [],
    defer: true,
    onSuccess: (application: ApplicationForm) => {
      getMyApplication.setData(application);
      setApplications(
        applications.map((a: ApplicationForm) => a.id === application.id ? application : a)
      )
      toast.success('Application Status Updated Successfully')
    },
    onError: (error: any) => NotifyFailure(error.response, error.message)
  }, [])

  const inProgress = updateApplicationStatus.inProgress || getRegistrationComponents.inProgress || !applicationComponents.applicationStatuses
  return (
    <div className={'application-status'}>
      <div className='section-header'>Application Status:</div>
      {inProgress
        ? <span style={{ textAlign: 'center' }}><CircularProgress color="inherit" /> </span>
        : currentUser && currentUser.isAHSAdmin
          ? <FormControlSelect
            field='applicationStatus'
            fieldId='id'
            fieldName='name'
            index={false}
            options={applicationComponents.applicationStatuses}
            applicationDetails={application}
            setApplicationDetails={updateApplicationStatus.execute}
          />
          : <div className='description'>{application.applicationStatus.name}</div>
      }
    </div>
  );
}

export default ApplicationDetailsModal;