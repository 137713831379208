import './index.css'

import { Fragment, FunctionComponent, useContext, useEffect, useMemo, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { toast } from 'react-toastify';

import Wrapper from '../Wrapper';
import { MyApplicationContext } from 'store';
import useApi from 'shared/utils/react_use_api';
import * as applicationsApi from "api/applications";
import { isNullOrEmpty } from "shared/utils/string";
import { openInNewTab } from 'shared/utils/actions';
import NotifyFailure from 'shared/utils/notify-failure';
import { ApplicationAttachment, ApplicationForm, UseApi } from 'api/applications/types';
import DocumentUpload from "registration/shared/components/file-upload";
import React from 'react';

const fileInitialValue = {
  value: '',
  filename: ''
}

const ApplicationAttachments: FunctionComponent = () => {
  const [file, setFile] = useState(fileInitialValue)
  const getMyApplication = useContext<UseApi>(MyApplicationContext);
  const [attachmentDescription, setAttachmentDescription] = useState('');

  const application: ApplicationForm = useMemo(() => getMyApplication.data, [getMyApplication.data]);


  const uploadAttachment = useApi({
    action: (attachment: FormData) => applicationsApi.uploadAttachment(attachment),
    defer: true,
    onSuccess: (application: ApplicationForm) => {
      getMyApplication.setData(application);

      toast.success(`${formatLabel(attachmentDescription)} updated successfully`);

      setFile(fileInitialValue)
    },
    onError: (error: any) => NotifyFailure(error.response, error.message)
  }, [])

  useEffect(() => {
    if (!isNullOrEmpty(file.filename)) {
      const formData: any = new FormData();
      formData.append('image', file.value)
      formData.append('applicationId', application.id.toString())
      formData.append('attachmentDescription', attachmentDescription)

      if (!isNullOrEmpty(attachmentDescription)) uploadAttachment.execute(formData);
    }
  }, [file.filename])

  const requiredAttachments = () => [
    {
      placeHolder: 'Proof Of Payment File',
      attachmentDescription: 'ProofOfPaymentFileURL',
      isEnabled: (application.applicationStatus.name !== 'Not Accepted' && application.applicationStatus.name !== 'Accepted' && application.applicationStatus.name !== 'Multiple invalid proof of payment upload attempts')
    },
    {
      placeHolder: 'Grade 6 Final Report',
      attachmentDescription: 'Grade6FinalReportFileURL',
      isEnabled: application.grade.name === 'Grade 8'
    },
    {
      placeHolder: 'Grade 7 Term1 Report',
      attachmentDescription: 'Grade7Term1ReportFileURL',
      isEnabled: application.grade.name === 'Grade 8'
    },
    {
      placeHolder: 'Grade 7 Final Report',
      attachmentDescription: 'Grade7FinalReportFileURL',
      isEnabled: application.grade.name === 'Grade 9'
    },
    {
      placeHolder: 'Grade 8 Term1 Report',
      attachmentDescription: 'Grade8Term1ReportFileURL',
      isEnabled: application.grade.name === 'Grade 9'
    },
    {
      placeHolder: 'Grade 8 Final Report',
      attachmentDescription: 'Grade8FinalReportFileURL',
      isEnabled: application.grade.name === 'Grade 10'
    },
    {
      placeHolder: 'Grade 9 Term1 Report',
      attachmentDescription: 'Grade9Term1ReportFileURL',
      isEnabled: application.grade.name === 'Grade 10'
    },
    {
      placeHolder: 'Grade 9 Final Report',
      attachmentDescription: 'Grade9FinalReportFileURL',
      isEnabled: application.grade.name === 'Grade 11'
    },
    {
      placeHolder: 'Grade 10 Term1 Report',
      attachmentDescription: 'Grade10Term1ReportFileURL',
      isEnabled: application.grade.name === 'Grade 11'
    },
    {
      placeHolder: 'Grade 10 Final Report',
      attachmentDescription: 'Grade10FinalReportFileURL',
      isEnabled: application.grade.name === 'Grade 12'
    },
    {
      placeHolder: 'Grade 11 Term1 Report',
      attachmentDescription: 'Grade11Term1ReportFileURL',
      isEnabled: application.grade.name === 'Grade 12'
    },
    {
      placeHolder: 'BirthCerificate/ApplicationReciept',
      attachmentDescription: 'UnabridgedBirthCerificateFileURL',
      isEnabled: true
    },
    {
      placeHolder: 'Parents ID Documents File',
      attachmentDescription: 'ParentsIDDocumentsFileURL',
      isEnabled: true
    },
    {
      placeHolder: 'Immunisation Record File',
      attachmentDescription: 'ImmunisationRecordFileURL',
      isEnabled: true
    }
  ]

  function formatLabel(inputString: string) {
    // Remove last 3 letters
    const trimmedString = inputString.slice(0, -3);
  
    // Apply space before each uppercase letter
    const stringWithSpaces = trimmedString.replace(/([A-Z])/g, ' $1');
  
    return stringWithSpaces;
  }

  const Component = () => (
    <Fragment>
      {application.applicationAttachments.map((a: ApplicationAttachment) => (
        <div className='key-value-pair' key={a.attachmentDescription}>
          <div className='key-value-pair-key'>{formatLabel(a.attachmentDescription)}:</div>
          <div
            className='key-value-pair-link'
            onClick={() => openInNewTab(a.attachmentFileURL)}>
            VIEW HERE
          </div>
        </div>
      ))}
      {application && requiredAttachments().map(a => (
        <div 
          key={a.attachmentDescription}
          className="attachment-file-upload-container" 
          style={{ display: a.isEnabled ? 'inherit' : 'none'}}
          onMouseEnter={() => setAttachmentDescription(a.attachmentDescription)}>
          <DocumentUpload
            file={file}
            setFile={setFile}
            filenamePlaceholder={a.placeHolder}
          />
        </div>
      ))}
      
    </Fragment>
  );

  return (
    <Wrapper>
      <React.Fragment>
        <div className='section-header'>Application Attachments</div>
        {uploadAttachment.inProgress
          ? <div style={{ textAlign: 'center' }}> <CircularProgress color="inherit" /> </div>
          : <Component />
        }
      </React.Fragment>
    </Wrapper>
  )
}

export default ApplicationAttachments;