/* eslint-disable react/prop-types */
import "./index.css";

import { FunctionComponent, useContext } from 'react'
import { useNavigate } from "react-router-dom";

import { RegistrationModalVisibilityContext } from "registration/store";
import { CurrentUserContext, MyApplicationContext } from "store";
import { isEmpty } from "shared/utils/string";
import { UseApi } from "api/applications/types";

const MainBanner: FunctionComponent = () => {
  const navigate = useNavigate();
  const [currentUser] = useContext(CurrentUserContext);
  const getMyApplication = useContext<UseApi>(MyApplicationContext);
  const [, setRegistrationModalVisible] = useContext(RegistrationModalVisibilityContext)

  return (
    <div className='main-banner'>
      <div className="banner-overlay-content">
        <div className="first-text">Where Learners Become Family</div>
        <div className="second-text">ALBINI HIGH SCHOOL</div>
        <div className="buttons">
        <div
          className="apply-now-btn"
          onClick={() => {
            if(isEmpty(currentUser)) navigate('/login');
            else setRegistrationModalVisible(true)
          }}><span>APPLY NOW</span>
        </div>
        <div
          className="my-application-btn"
          onClick={() => {
            getMyApplication.setData({})
            navigate(`/application/${currentUser.id}`)
          }}>
          <span>MY APPLICATION</span>
        </div>
        </div>
      </div>
    </div>
  );
};

export default MainBanner;
