import React, { FunctionComponent, useContext, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";

import * as authApi from "api/authentication";
import { User } from 'api/authentication/types';
import useApi from 'shared/utils/react_use_api';
import NotifyFailure from 'shared/utils/notify-failure';
import { CurrentUserContext } from "store";

const theme = createTheme({
  /*
  palette: {
    primary: {
      main: '#f5b714',
      contrastText: "#fff"
    }
  }
  */
});


const PhoneNumberVerification: FunctionComponent = () => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useContext(CurrentUserContext);
  const [phoneNumberVerificationCodeSent, setPhoneNumberVerificationCodeSent] = useState(false);

  const sendPhoneNumberVerificationCode = useApi({
    action: (user: any) => authApi.sendPhoneNumberVerificationCode(user),
    defer: true,
    onSuccess: (user: User) => {
      setCurrentUser(user);

      setPhoneNumberVerificationCodeSent(true);

      toast.success(`Phone number verification code sent to: ${user.phoneNumber}`)
    },
    onError: async (e: any) => NotifyFailure(e.response, e.message)
  }, [])

  const verifyPhoneNumber = useApi({
    action: (currentUser: User) => authApi.verifyPhoneNumber(currentUser),
    defer: true,
    onSuccess: async (user: User) => {
      setCurrentUser(user)

      localStorage.setItem('albini-high-school-user', JSON.stringify(user));

      toast.success(`Phone number verified successfully`)

      navigate('/');
    },
    onError: (error: any) => NotifyFailure(error.response, error.message)
}, [])

  const handlePhoneNumberVerificationRequest = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data: any = new FormData(event.currentTarget);
    const payload: any = {
      ...currentUser,
      phoneNumber: data.get('phoneNumber'),
    }
    sendPhoneNumberVerificationCode.execute(payload);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data: any = new FormData(event.currentTarget);
    verifyPhoneNumber.execute({
      ...currentUser,
      phoneVerificationCode: data.get('phoneNumberVerificationCode'),
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${require("../../assets/images/login-page/banner.jpeg")})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar
              alt="Remy Sharp"
              sx={{ width: 100, height: 100 }}
              src={require('../../assets/logo-square.png')}
            />
            <Typography component="h1" variant="h5">
              Phone Number Verification
            </Typography>
            {!phoneNumberVerificationCodeSent && (
              <Box component="form" noValidate onSubmit={handlePhoneNumberVerificationRequest} sx={{ mt: 3 }}>
                <TextField
                  required
                  fullWidth
                  id="phoneNumber"
                  label="Phone Number"
                  name="phoneNumber"
                  value={currentUser.phoneNumber}
                  autoComplete="phoneNumber"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {sendPhoneNumberVerificationCode.inProgress ? <CircularProgress color="inherit" /> : 'Send Verification Code'}
                </Button>
                <Grid container justifyContent="space-between">
                  <Grid item onClick={() => navigate('/login')}>
                    <Link href="#" variant="body2">
                      Account Login
                    </Link>
                  </Grid>
                  <Grid item onClick={() => navigate('/signup')}>
                    <Link href="#" variant="body2">
                      Create Account
                    </Link>
                  </Grid>
                </Grid>
                <Typography variant="body2" color="text.primary" align="center" sx={{ mt: 5 }}>
                  <RouterLink to={"/"}>
                    Home
                  </RouterLink>
                </Typography>
              </Box>
            )}
            {phoneNumberVerificationCodeSent && (
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <TextField
                  required
                  fullWidth
                  id="phoneNumberVerificationCode"
                  label="Phone Number Verification Code"
                  name="phoneNumberVerificationCode"
                  autoComplete="phoneNumberVerificationCode"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {verifyPhoneNumber.inProgress ? <CircularProgress color="inherit" /> : 'Verify Phone Number'}
                </Button>
                <Grid container justifyContent="space-between">
                  <Grid item onClick={() => navigate('/login')}>
                    <Link href="#" variant="body2">
                      Account Login
                    </Link>
                  </Grid>
                  <Grid item onClick={() => navigate('/signup')}>
                    <Link href="#" variant="body2">
                      Create Account
                    </Link>
                  </Grid>
                </Grid>
                <Typography variant="body2" color="text.primary" align="center" sx={{ mt: 5 }}>
                  <RouterLink to={"/"}>
                    Home
                  </RouterLink>
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default PhoneNumberVerification;