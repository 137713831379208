/* eslint-disable react/prop-types */
import "./index.css";

import { FunctionComponent } from 'react';
import Slider from 'react-touch-drag-slider'

const Testimonials: FunctionComponent = () => {
  return (
    <div id='testimonials' className='testimonials'>
        <div className="title">TESTIMONIALS</div>
        <div className="sub-title">HEAR IT FROM...</div>
        <Slider
        >
          <div key={1} className="testimonial">
            <strong className="title">Alumni</strong>
            <p className="paragraph">“Out of all the school I applied at, I chose Albini high because it was well known for its outstanding performance and discipline, and I am glad I got to experience all that first hand. During my first day at Albini I was scared since it has many Zulus, and I was Xhosa but the teachers, the learners in fact the whole school did not treat me any different but rather they made me more comfortable than ever and gave me sense of belonging. Inside the premises we did not only share the Teacher-learner relationship, but we were also family.”</p>
            <p className="author">ANELE THALENTE MDUDUMA</p>
            <p className="date">MAY 2022</p>
          </div>
          <div key={2} className="testimonial">
            <strong className="title">A School Parent </strong>
            <p className="paragraph">“Choosing a high school for my child was one of the most important decisions of my life. I couldn’t be happier with my choice. My child finished primary school with a few key strengths in sports that we wanted to nurture, but we wanted her to grow academically and culturally at the same time. At Albini High School, she was exposed to a range of different activities and disciplines, growing into her likes and fine-tuning her strengths under excellent instruction. School has become a fundamental part of all of our lives – and I wouldn’t change a thing.”</p>
            <p className="author">ERIC ZULU</p>
            <p className="date">OCT 2014</p>
          </div>
        </Slider>
    </div>
  );
};

export default Testimonials;
