import { FunctionComponent, useContext, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { CurrentUserContext } from "store";
import { userInitialState } from '../contants';
import * as authApi from "api/authentication";
import { User } from 'api/authentication/types';
import useApi from 'shared/utils/react_use_api';
import NotifyFailure from 'shared/utils/notify-failure';
import Login from './component';

const AuthenticationController: FunctionComponent = () => {
    const navigate = useNavigate();
    const [user] = useState<User>(userInitialState)
    const [, setCurrentUser] = useContext(CurrentUserContext);

    const postLogin = useApi({
        action: (user: User) => authApi.postLogin(user),
        defer: true,
        onSuccess: (user: User) => {
            localStorage.setItem('albini-high-school-user', JSON.stringify({
                ...user,
                tutorAttribute: null
            }));

            setCurrentUser(user);

            //if(user.isPhoneNumberVerified) {

                toast.info(`Welcome ${user.firstName}`)

                navigate('/');
            //}
            //else navigate('/phone-number-verification');
        },
        onError: async (e: any) => {
            if(e.response && e.response.data && e.response.data.errorMessages[0].includes(user.phoneNumber)) {
                localStorage.removeItem('albini-high-school-user');
            }

            return NotifyFailure(e.response, e.message)
        }
    }, [])

    return (
        <Login
            user={user}
            inProgress={postLogin.inProgress}
            onLogin={(user: any) => postLogin.execute(user)}
        />
    )
}

export default AuthenticationController;