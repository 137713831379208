import { useState, createContext, useEffect } from 'react';
import NotifyFailure from 'shared/utils/notify-failure';
import useApi from 'shared/utils/react_use_api';
import * as applicationsApi from "api/applications";

export const CurrentUserContext = createContext<any>({})
export const ApplicationsContext = createContext<any>({})
export const MyApplicationContext = createContext<any>({})

export default function Store(props: any) {
	const [currentUser, setCurrentUser] = useState({})
	const [applications, setApplications] = useState([])

    useEffect(() => {
		const localStorageUser = localStorage.getItem('albini-high-school-user')
		if (localStorageUser != null) setCurrentUser(JSON.parse(localStorageUser))
	}, [])

	const getMyApplication = useApi({
		action: (userId: number) => applicationsApi.getMyApplication(userId),
		initialValue: {},
		defer: true,
		onError: (error: any) => NotifyFailure(error.response, error.message)
	  }, [])

	return (
		<CurrentUserContext.Provider value={[currentUser, setCurrentUser]}>
			<ApplicationsContext.Provider value={[applications, setApplications]}>
				<MyApplicationContext.Provider value={getMyApplication}>
					{props.children}
				</MyApplicationContext.Provider>
			</ApplicationsContext.Provider>
		</CurrentUserContext.Provider>
	);
};