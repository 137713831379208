import { FunctionComponent, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import * as applicationsApi from "api/applications";
import * as registrationApi from "api/applications";
import useApi from 'shared/utils/react_use_api';
import NotifyFailure from 'shared/utils/notify-failure';
import Applications from './list';
import { Application, RegistrationComponents } from "api/applications/types";
import { isEmpty } from "shared/utils/string";
import { ApplicationsContext, CurrentUserContext } from "store";
import { RegistrationComponentsContext } from "registration/store";

const ApplicationsController: FunctionComponent = () => {
    const navigate = useNavigate();
    const [currentUser] = useContext(CurrentUserContext);
    const [applications, setApplications] = useContext(ApplicationsContext)
    const [applicationComponents, setRegistrationComponents] = useContext(RegistrationComponentsContext)

    useEffect(() => {
        if(isEmpty(currentUser)) navigate('/login');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getApplications = useApi({
        action: () => applicationsApi.get(currentUser),
        initialValue: [],
        condition: !applications.length && !isEmpty(currentUser),
        onSuccess: (response: Application[]) => setApplications(response),
        onError: (error: any) => NotifyFailure(error.response, error.message)
    }, [currentUser])

    const getRegistrationComponents = useApi({
        action: () => registrationApi.getRegistrationComponents(),
        condition: !applicationComponents.applicationStatuses,
        onSuccess: (registrationComponents: RegistrationComponents) => setRegistrationComponents(registrationComponents),
        onError: (error: any) => NotifyFailure(error.response, error.message)
    }, [])

    let inProgress = getApplications.inProgress
                  || getRegistrationComponents.inProgress

    return (
        <Applications inProgress={inProgress} />
    )
}

export default ApplicationsController;