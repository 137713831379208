import './index.css'

import React, { FunctionComponent, useContext, useMemo, useRef } from "react";
import moment from 'moment';

import { isNullOrEmpty } from 'shared/utils/string';
import Address from './Address';
import ApplicationStatus from './ApplicationStatus';
import { openInNewTab } from 'shared/utils/actions';
import { USER_FRIENDLY_FORMAT } from 'shared/constants/strings';
import { CurrentUserContext, MyApplicationContext } from 'store';
import Wrapper from './Wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { useReactToPrint } from 'react-to-print';

const MyApplication: FunctionComponent = () => {
    const componentRef = useRef(null);
    const [currentUser] = useContext(CurrentUserContext);
    const getMyApplication = useContext(MyApplicationContext);

    const application = useMemo(() => getMyApplication.data, [getMyApplication.data]);

    const handlePdfDownload = useReactToPrint({
        content: () => componentRef.current
      });
      
    const ApplicationComponent = () => (
        <React.Fragment>
            <div className='section-header-red' id='section-header-red'>Non-Refundable Application Fee (R100)</div>
            {currentUser && currentUser.isAHSAdmin && (
                <div className='download-pdf-container'>
                    <button className='download-pdf-button' onClick={handlePdfDownload}>
                        <FontAwesomeIcon
                            size='lg'
                            color='maroon'
                            icon={faFilePdf}
                        /> PRINT PDF
                    </button>
                </div>
            )}
            <div className='applicant-name-container'>
                <div className='applicant-name'>
                    {`${application.learnerDetails.firstNamesFull} ${application.learnerDetails.surname}`}
                </div>
            </div>
            <div className='grade'>{application.grade.name}</div>
            <ApplicationStatus
                application={application}
            />
            <div ref={componentRef}>
                <div className='section-header'>SECTION A</div>
                <div className='section-header'>LEARNER DETAILS</div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>SURNAME:</div>
                    <div className='key-value-pair-value'>{application.learnerDetails.surname}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>FIRST NAMES(in full):</div>
                    <div className='key-value-pair-value'>{application.learnerDetails.firstNamesFull}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>DATE OF BIRTH (dd/mm/yy):</div>
                    <div className='key-value-pair-value'>{moment(application.learnerDetails.dateOfBirth).format(USER_FRIENDLY_FORMAT)}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>ID NO:</div>
                    <div className='key-value-pair-value'>{application.learnerDetails.idNumber}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>HOME LANGUAGE:</div>
                    <div className='key-value-pair-value'>{application.learnerDetails.homeLanguage}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>CITIZENSHIP:</div>
                    <div className='key-value-pair-value'>{application.learnerDetails.citizenship}</div>
                </div>
                {!isNullOrEmpty(application.learnerDetails.notSACitizenResidencyCopyURL) && (
                    <div className='key-value-pair'>
                        <div className='key-value-pair-key'>COPY OF RESIDENCY:</div>
                        <div
                            className='key-value-pair-link'
                            onClick={() => openInNewTab(application.learnerDetails.notSACitizenResidencyCopyURL)}>
                            RESIDENCY
                        </div>
                    </div>
                )}
                {!isNullOrEmpty(application.learnerDetails.notSACitizenPermitCopyURL) && (
                    <div className='key-value-pair'>
                        <div className='key-value-pair-key'>COPY OF PERMIT:</div>
                        <div
                            className='key-value-pair-link'
                            onClick={() => openInNewTab(application.learnerDetails.notSACitizenPermitCopyURL)}>
                            PERMIT
                        </div>
                    </div>
                )}
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>COUNTRY OF RESIDENCE:</div>
                    <div className='key-value-pair-value'>{application.learnerDetails.countryOfResidence}</div>
                </div>
                <Address
                    description='PHYSICAL ADDRESS'
                    address={application.learnerDetails.physicalAddress}
                />
                <Address
                    description='POSTAL ADDRESS'
                    address={application.learnerDetails.postalAddress}
                />
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>DECEASED PARENT:</div>
                    <div className='key-value-pair-value'>{application.learnerDetails.deceasedParent}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>DEXTERITY:</div>
                    <div className='key-value-pair-value'>{application.learnerDetails.dexterity}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>NAME OF PRESENT SCHOOL:</div>
                    <div className='key-value-pair-value'>{application.learnerDetails.nameOfPresentSchool}</div>
                </div>
                <Address
                    description='PRESENT SCHOOL ADDRESS'
                    address={application.learnerDetails.presentSchoolAddress}
                />
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>SCHOOL TEL. NO:</div>
                    <div className='key-value-pair-value'>{application.learnerDetails.schoolTelNumber}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>HAS THE LEARNER REPEATED A GRADE:</div>
                    <div className='key-value-pair-value'>{application.learnerDetails.hasTheLeanerRepeatedAGrade ? 'YES' : 'NO'}</div>
                </div>
                {application.learnerDetails.hasTheLeanerRepeatedAGrade && (
                    <div className='key-value-pair'>
                        <div className='key-value-pair-key'>IF YES WHICH GRADE:</div>
                        <div className='key-value-pair-value'>{application.learnerDetails.gradeRepeated}</div>
                    </div>
                )}
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>SOCIAL GRANT NUMBER:</div>
                    <div className='key-value-pair-value'>{application.learnerDetails.socialGrantNummber}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>MODE OF TRANSPORT:</div>
                    <div className='key-value-pair-value'>{application.learnerDetails.modeOfTransport}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>RELIGION(CHURCH NAME):</div>
                    <div className='key-value-pair-value'>{application.learnerDetails.religionChurchName}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>DO YOU HAVE ANY SIBLINGS IN THIS SCHOOL:</div>
                    <div className='key-value-pair-value'>{application.learnerDetails.doYouHaveAnySiblingsInTheSchool ? 'YES' : 'NO'}</div>
                </div>
                {application.learnerDetails.doYouHaveAnySiblingsInTheSchool && (
                    <div className='key-value-pair'>
                        <div className='key-value-pair-key'>IN WHICH GRADE:</div>
                        <div className='key-value-pair-value'>{application.learnerDetails.gradeRepeated}</div>
                    </div>
                )}
                <div className='section-header'>MEDICAL INFORMATION</div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>HEALTH:</div>
                    <div className='key-value-pair-value'>{application.learnerDetails.health}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>PROBLEM/ALLERGIES:</div>
                    <div className='key-value-pair-value'>{application.learnerDetails.problemOrAllergies}</div>
                </div>
                <div className='section-header'>SECTION B</div>
                <div className='section-header'>MOTHER'S DETAILS</div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>TITLE</div>
                    <div className='key-value-pair-value'>{application.mothersDetails.title}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>INITIALS</div>
                    <div className='key-value-pair-value'>{application.mothersDetails.initials}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>SURNAME</div>
                    <div className='key-value-pair-value'>{application.mothersDetails.surname}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>FIRST NAME IN FULL</div>
                    <div className='key-value-pair-value'>{application.mothersDetails.firstNameInFull}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>HOME LANGUAGE</div>
                    <div className='key-value-pair-value'>{application.mothersDetails.homeLanguage}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>RACE</div>
                    <div className='key-value-pair-value'>{application.mothersDetails.race}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>IDENTITY NUMBER</div>
                    <div className='key-value-pair-value'>{application.mothersDetails.idNumber}</div>
                </div>
                <Address
                    description='RESIDENTIAL ADDRESS'
                    address={application.mothersDetails.residentialAddress}
                />
                <Address
                    description='POSTAL ADDRESS'
                    address={application.mothersDetails.postalAddress}
                />
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>MARITAL STATUS</div>
                    <div className='key-value-pair-value'>{application.mothersDetails.maritalStatus}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>CELL NO/EMERGENCY NO.</div>
                    <div className='key-value-pair-value'>{application.mothersDetails.cellOrEmergencyNumber}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>OCCUPATION</div>
                    <div className='key-value-pair-value'>{application.mothersDetails.occupation}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>NAME OF EMPLOYER/BUSINESS</div>
                    <div className='key-value-pair-value'>{application.mothersDetails.nameOfEmployerOrBusiness}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>RELATIONSHIP TO LEARNER</div>
                    <div className='key-value-pair-value'>{application.mothersDetails.relationshipToLearner}</div>
                </div>
                <div className='section-header'>FATHER'S DETAILS</div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>SURNAME</div>
                    <div className='key-value-pair-value'>{application.fathersDetails.surname}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>FIRST NAME IN FULL</div>
                    <div className='key-value-pair-value'>{application.fathersDetails.firstNameInFull}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>HOME LANGUAGE</div>
                    <div className='key-value-pair-value'>{application.fathersDetails.homeLanguage}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>RACE</div>
                    <div className='key-value-pair-value'>{application.fathersDetails.race}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>IDENTITY NUMBER</div>
                    <div className='key-value-pair-value'>{application.fathersDetails.idNumber}</div>
                </div>
                <Address
                    description='RESIDENTIAL ADDRESS'
                    address={application.fathersDetails.residentialAddress}
                />
                <Address
                    description='POSTAL ADDRESS'
                    address={application.fathersDetails.postalAddress}
                />
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>OCCUPATION</div>
                    <div className='key-value-pair-value'>{application.fathersDetails.occupation}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>MARITAL STATUS</div>
                    <div className='key-value-pair-value'>{application.fathersDetails.maritalStatus}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>CELL NO/EMERGENCY NO.</div>
                    <div className='key-value-pair-value'>{application.fathersDetails.cellOrEmergencyNumber}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>NAME OF EMPLOYER/BUSINESS</div>
                    <div className='key-value-pair-value'>{application.fathersDetails.nameOfEmployerOrBusiness}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>WHO DOES THE LEARNER LIVE WITH</div>
                    <div className='key-value-pair-value'>{application.fathersDetails.whoDoesThelearnerLiveWith}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>RELATIONSHIP TO LEARNER</div>
                    <div className='key-value-pair-value'>{application.fathersDetails.relationshipToLearner}</div>
                </div>
                <div className='section-header'>GUARDIAN</div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>SURNAME</div>
                    <div className='key-value-pair-value'>{application.guardian.surname}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>FIRST NAME IN FULL</div>
                    <div className='key-value-pair-value'>{application.guardian.firstNameInFull}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>HOME LANGUAGE</div>
                    <div className='key-value-pair-value'>{application.guardian.homeLanguage}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>RACE</div>
                    <div className='key-value-pair-value'>{application.guardian.race}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>IDENTITY NUMBER</div>
                    <div className='key-value-pair-value'>{application.guardian.idNumber}</div>
                </div>
                <Address
                    description='RESIDENTIAL ADDRESS'
                    address={application.guardian.residentialAddress}
                />
                <Address
                    description='POSTAL ADDRESS'
                    address={application.guardian.postalAddress}
                />
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>CELL NO/EMERGENCY NO.</div>
                    <div className='key-value-pair-value'>{application.guardian.cellOrEmergencyNumber}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>OCCUPATION</div>
                    <div className='key-value-pair-value'>{application.guardian.occupation}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>NAME OF EMPLOYER</div>
                    <div className='key-value-pair-value'>{application.guardian.nameOfEmployer}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>RELATIONSHIP TO LEARNER</div>
                    <div className='key-value-pair-value'>{application.guardian.relationshipToLearner}</div>
                </div>
                <div className='paragraph'>
                    I hereby declare that to the best of my knowledge the above information as supplied is accurate and correct
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>NAME OF PARENT/GUARDIAN</div>
                    <div className='key-value-pair-value'>{application.guardian.nameOfParentOrGuardian}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>SIGNATURE OF PARENT/GUARDIAN</div>
                    <div className='key-value-pair-value'>{application.guardian.signatureOfParentOrGuardian}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>DATE</div>
                    <div className='key-value-pair-value'>{moment(application.guardian.date).format(USER_FRIENDLY_FORMAT)}</div>
                </div>
                <div className='section-header'>SECTION C</div>
                <div className='section-header'>DECLARATION /UNDERTAKINGS BY APPLICANT AND PARENTS/GUARDIANS</div>
                <div className='paragraph'>
                    1. We have read and understod the statement and questions on this form. The information supplied by us,
                    individually or together, is complete and true in every respect. If any of the supplied information is found to be
                    incomplete, incorrect, untrue, misleading, the school may cancel offer of space and refuse to accept any future
                    application in respect of the same applicant.
                </div>
                <div className='paragraph'>
                    2. We undertake to accept and abide by the code of conduct of the school ,and such rules regulations are put in
                    place by the School Goverment Body from time to time. We accept further that the applicant will be under the
                    disciplinary control of the school from the date on which the commences her studied at the school ,to the date on
                    which she is withdrawn from or leaves the school.
                </div>
                <div className='paragraph'>
                    3. We accept that the school may
                </div>
                <div className='paragraph'>
                    3.1. report to the same people on any matter concerning the progress, conduct, well-being or health of the
                    applicant.
                </div>
                <div className='paragraph'>
                    3.2. phone the same people if the applicant become ill or injured or for any reason requiring medical attention.
                </div>
                <div className='paragraph'>
                    3.3. We accept liability for any damage to the school property caused by the applicant how so ever it may
                    occur.
                </div>
                <div className='paragraph'>
                    3.4. The address for the service of any formal notice in relation to this application may be served at the physocal
                    address listed in section B
                </div>
                <div className='paragraph'>
                    This form signed at {application.declaration.thisFormSignedAt} on this {application.declaration.thisFormSignedOnDay} day of {application.declaration.thisFormSignedOnMonth} 2024
                </div>
                <div className='section-header'>SIGNATURES:</div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>Signature of learner</div>
                    <div className='key-value-pair-value'>{application.declaration.signatureOfLearner}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>1. Name & Surname of Guardian:</div>
                    <div className='key-value-pair-value'>{application.declaration.nameAndSurnameOfGuardian1}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>Signature of Parent/Guardian:</div>
                    <div className='key-value-pair-value'>{application.declaration.signatureOfParentOrGuardian1}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>Name of witness:</div>
                    <div className='key-value-pair-value'>{application.declaration.NameOfWitness1}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>Signature of witness:</div>
                    <div className='key-value-pair-value'>{application.declaration.signatureOfWitness1}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>2. Name & Surname of Guardian:</div>
                    <div className='key-value-pair-value'>{application.declaration.nameAndSurnameOfGuardian2}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>Signature of Parent/Guardian:</div>
                    <div className='key-value-pair-value'>{application.declaration.signatureOfParentOrGuardian2}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>Name of witness:</div>
                    <div className='key-value-pair-value'>{application.declaration.nameOfWitness2}</div>
                </div>
                <div className='key-value-pair'>
                    <div className='key-value-pair-key'>Signature of witness:</div>
                    <div className='key-value-pair-value'>{application.declaration.signatureOfWitness2}</div>
                </div>
                <div className='paragraph'>
                    A FALSE DECLARATION OF ANY KIND WILL ENTITLE THE SCHOOL TO TERMINATE AN APPLICATION.
                </div>
            </div>
        </React.Fragment>
    );

    return (
        <Wrapper>
            <ApplicationComponent />
        </Wrapper>
    );
}

export default MyApplication;