import axios from 'axios';

import { User } from 'api/authentication/types';
import { apiBaseUrl } from 'shared/constants/configurations';
import { RegistrationComponents, Application, ApplicationForm, SubjectMark } from './types';

export const get = (currentUser: User) => axios.get(`${apiBaseUrl}/Applications/${currentUser.id}/${currentUser.isAHSAdmin}`).then(response => response.data)
export const getMyApplication = (userId: number) => axios.get(`${apiBaseUrl}/Applications/application/${userId}`).then(response => response.data)
export const getApplicationsStats = () => axios.get(`${apiBaseUrl}/Applications/application-stats`).then(response => response.data)
export const getRegistrationComponents = () : Promise<RegistrationComponents> => axios.get(`${apiBaseUrl}/Applications/components`).then(response => response.data)
export const post = (registration: Application) => axios.post(`${apiBaseUrl}/Applications/application`, registration).then(response => response.data)
export const postAttatchments = (attatchments: FormData) => axios.post(`${apiBaseUrl}/Applications/attatchments-to-urls`, attatchments).then(response => response.data)
export const updateApplicationStatus = (application: ApplicationForm) => axios.patch(`${apiBaseUrl}/Applications/application-status`, application).then(response => response.data)
export const postProofOfPayment = (proofOfPayment: FormData) => axios.post(`${apiBaseUrl}/Applications/proof-of-payment`, proofOfPayment).then(response => response.data);
export const createApplication = (registration: ApplicationForm) => axios.post(`${apiBaseUrl}/Applications`, registration).then(response => response.data)
export const uploadAttachment = (attatchment : FormData) => axios.post(`${apiBaseUrl}/Applications/upload-attachment`, attatchment).then(response => response.data);
export const addSubjectMark = (addSubject: SubjectMark) => axios.post(`${apiBaseUrl}/Applications/add-subject-mark`, addSubject).then(response => response.data)
export const deleteSubjectMark = (deleteSubject: SubjectMark) => axios.post(`${apiBaseUrl}/Applications/delete-subject-mark`, deleteSubject).then(response => response.data)
