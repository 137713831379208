import { FunctionComponent, useContext, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { CurrentUserContext } from "store";
import { userInitialState } from '../contants';
import * as authApi from "api/authentication";
import { User } from 'api/authentication/types';
import useApi from 'shared/utils/react_use_api';
import NotifyFailure from 'shared/utils/notify-failure';
import PasswordReset from './component';

const PasswordResetController: FunctionComponent = () => {
    const navigate = useNavigate();
    const [user] = useState<User>(userInitialState)
    const [, setCurrentUser] = useContext(CurrentUserContext);

    const passwordResetRequest = useApi({
        action: (user: User) => authApi.passwordResetRequest(user),
        defer: true,
        onSuccess: (user: User) => {
            setCurrentUser(user)

            localStorage.setItem('albini-high-school-user', JSON.stringify(user));

            toast.success(`Password has been reset successfully`)

            navigate('/');
        },
        onError: async (e: any) => NotifyFailure(e.response, e.message)
    }, [])

    return (
        <PasswordReset
            user={user}
            inProgress={passwordResetRequest.inProgress}
            passwordResetRequest={(user: any) => passwordResetRequest.execute(user)}
        />
    )
}

export default PasswordResetController;