import { Option } from './types';

export const options: Option[] = [
    {
      title: 'About',
      sections: [
        {
          id: 'about',
          type: 'header-sections',
          heading: 'About Our School',
          subSections: [{
              id:'about',
              description: 'About',
              isLink: false
            },
            { 
              id:'legacy',
              description:'Our history',
              isLink: false
          }]
        },
        {
          id: 'testimonials',
          type: 'header-sections',
          heading: "Parents & Alumni's",
          subSections: [{
            id:'testimonials',
            description:'Testimonials',
            isLink: false
          }]
        },
        {
          id: 'logo-slogan',
          type: 'logo-slogan',
          heading: '',
          subSections: [{
            id: 'logo-slogan',
            description: 'Logo Slogan',
            isLink: false
          }]
        }
      ],
    },
    {
        title: 'Admissions',
        sections: [
          {
            id:'admissions',
            type: 'header-sections',
            heading: 'Admissions Information',
            subSections: [{
              id: 'apply-now',
              description: 'Appy Now',
              isLink: false
            },{
              id: 'applications',
              description: 'Applications',
              isLink: true
            },{
              id: 'applications-stats',
              description: 'Applications stats',
              isLink: true
            }]
          },
          {
            id:'footer',
            type: 'header-sections',
            heading: 'Contact',
            subSections: [{
              id: 'footer',
              description: 'Contact details',
              isLink: false
            }]
          },
          {
              id:'logo-slogan',
              type: 'logo-slogan',
              heading: '',
              subSections: []
          },
        ]
    }
  ]