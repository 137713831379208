import { FunctionComponent, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Registration from "./component";
import { 
    ApplicationDetailsContext,
    RegistrationComponentsContext,
    RegistrationModalVisibilityContext
} from "./store";
import { CurrentUserContext } from 'store';
import * as registrationApi from "api/applications";
import useApi from 'shared/utils/react_use_api';
import NotifyFailure from 'shared/utils/notify-failure';
import { ApplicationAttachmentsResponse, RegistrationComponents } from "api/applications/types";
import { toast } from "react-toastify";

const RegistrationController: FunctionComponent = () => {
    const navigate = useNavigate();
    const [currentUser] = useContext(CurrentUserContext);
    const [application] = useContext(ApplicationDetailsContext)
    const [, setRegistrationComponents] = useContext(RegistrationComponentsContext)
    const [, RegistrationModalVisible] = useContext(RegistrationModalVisibilityContext)

    useEffect(() => {
        if(currentUser == null) navigate('/login')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getRegistrationComponents = useApi({
        action: () => registrationApi.getRegistrationComponents(),
        onSuccess: (registrationComponents: RegistrationComponents) => setRegistrationComponents(registrationComponents),
        onError: (error: any) => NotifyFailure(error.response, error.message)
    }, [])

    const postAttacthments = useApi({
        action: () => {
            const formData = new FormData();
            formData.append('PermitCopyFile', application.learnerDetails.permitCopyFile)
            formData.append('ResidencyCopyFile', application.learnerDetails.residencyCopyFile)
            return registrationApi.postAttatchments(formData)
        },
        defer: true,
        onSuccess: (reponse: ApplicationAttachmentsResponse) => post.execute({
                ...application,
                learnerDetails: {
                    ...application.learnerDetails,
                    notSACitizenPermitCopyURL: reponse.permitCopyURL,
                    notSACitizenResidencyCopyURL: reponse.residencyCopyURL
                }
            })
        ,
        onError: (error: any) => NotifyFailure(error.response, error.message)
    }, [])

    const post = useApi({
        action: () => registrationApi.createApplication(application),
        defer: true,
        onSuccess: () => {
            toast.success('Application submitted successfully');

            RegistrationModalVisible(false);

            navigate(`/application/${currentUser.id}`)
        },
        onError: async (e: any) => NotifyFailure(e.response, e.message)
    }, [])

    let inProgress = post.inProgress || getRegistrationComponents.inProgress || postAttacthments.inProgress
    
    return (
        <Registration
            inProgress={inProgress}
            onSubmitRegistration={() => post.execute()}
            onSubmitRegistrationWithAttachments={() => postAttacthments.execute()}
        />
    )
}

export default RegistrationController;